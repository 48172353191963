import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CodeIcon from '@material-ui/icons/Code';
import ViewListIcon from '@material-ui/icons/ViewList';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BugReportIcon from '@material-ui/icons/BugReport';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import CommentIcon from '@material-ui/icons/Comment';
import PeopleIcon from '@material-ui/icons/People';
import { withResizeDetector } from 'react-resize-detector';
import AceEditor from "react-ace";
import ReactMarkdown from 'react-markdown';
import { JSHINT } from "jshint";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-searchbox";

import FileListEditor from "./FileListEditor";
import ParamEditor from "./ParamEditor";
import NameLabel from "./NameLabel";
import Debugger from "./Debugger";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import ShareDialog from "./ShareDialog";

const DEFAULT_CREATOR_LOG_TYPE = 'pseudonymized';
const DEFAULT_AUTHOR_LOG_TYPE = 'none';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
  },
  fullscreenRoot: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(9),
    bottom: 0,
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    '& > *': {
      margin: '0.5em',
    },
  },
  close: {
    marginLeft: "auto",
  },
  lastEvalError: {
    margin: "0.5em",
    color: "red",
  },
  editorPanel: {
    borderTop: '1px solid #ccc',
    textAlign: "left",
  },
  statusBar: {
    display: 'flex',
    borderTop: '1px solid #ccc',
    maxWidth: 'none',
    padding: '0.5em',
  },
  helpIcon: {
    '& > svg': {
      fontSize: '1.1em',
    },
  },
  deployHeader: {
    fontSize: '1.5em',
    marginTop: '0.5em',
  },
  deploySubHeader: {
    fontSize: '1.2em',
    marginTop: '0.5em',
  },
  deployParam: {
    marginLeft: '1em',
  },
  needToDeploy: {
    color: 'red',
    paddingLeft: '1em',
    fontSize: '0.7em',
  },
  deployButton: {
    margin: '1em 0',
  },
  taskUrl: {
    fontWeight: 'bold',
    display: 'block',
    fontSize: '1.2em',
    padding: '0.2em',
    textAlign: 'left',
  },
  urlCopied: {
    fontSize: '0.9em',
    marginTop: '-0.2em',
  },
  deployPanel: {
    overflowY: 'scroll',
  },
  policyError: {
    color: 'red',
    fontWeight: 'bold',
  },
});

class TaskEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskOpen: true,
      taskFullscreen: false,
      taskSaving: false,
      debugLinesUpdated: false,
      share: false,
      confirmDelete: false,
    };
    this.scriptEditor = null;
    this.toolbarRef = React.createRef();
    this.editorBarRef = React.createRef();
    this.statusBarRef = React.createRef();
    this.validationTimerId = null;
  }

  componentDidMount() {
    this.reserveValidateScript();
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkDebugLines(prevProps);
    if (this.state.taskFullscreen !== prevState.taskFullscreen) {
      if (this.props.onFullscreen) {
        this.props.onFullscreen(this.state.taskFullscreen);
      }
    }
    const currentScript = ((this.props.userTask || {}).data || {}).script;
    const prevScript = ((prevProps.userTask || {}).data || {}).script;
    if (currentScript === prevScript) {
      return;
    }
    this.reserveValidateScript();
  }

  checkDebugLines(prevProps) {
    const prevDebugLines = prevProps.debugLines || [];
    const debugLines = this.props.debugLines || [];
    if (prevDebugLines.length === debugLines.length) {
      return;
    }
    setTimeout(() => this.setState({
      debugLinesUpdated: this.state.editorTab === 2 ? false : true,
    }), 0);
  }

  validateScript(newValue) {
    const currentScript = newValue || ((this.props.userTask || {}).data || {}).script;
    if (!currentScript) {
      return;
    }
    JSHINT(currentScript, {
      esversion: "6",
    });
    const scriptErrors = JSHINT.errors.map((error) => ({
      row: error.line - 1,
      column: error.character - 1,
      type: 'error',
      text: error.reason,
    }));
    this.setState({
      scriptErrors,
    });
    if (this.scriptEditor) {
      this.scriptEditor.getSession().setAnnotations(scriptErrors);
    }
  }

  reserveValidateScript() {
    if (this.validationTimerId !== null) {
      return;
    }
    this.validationTimerId = setTimeout(() => {
      this.validateScript();
      this.validationTimerId = null;
    }, 10);
  }

  scriptChange(newValue) {
    if (!this.props.onScriptChange) {
      return;
    }
    this.props.onScriptChange(newValue);
  }

  titleChange(newValue) {
    if (!this.props.onTitleChange) {
      return;
    }
    this.props.onTitleChange(newValue);
  }

  descriptionChange(newValue) {
    if (!this.props.onDescriptionChange) {
      return;
    }
    this.props.onDescriptionChange(newValue);
  }

  paramChange(param) {
    if (!this.props.onParamChange) {
      return;
    }
    this.props.onParamChange(param);
  }

  paramSchemaChange(param) {
    if (!this.props.onParamSchemaChange) {
      return;
    }
    this.props.onParamSchemaChange(param);
  }

  filesDrop(files, callback) {
    if (!this.props.onFilesUpload) {
      return;
    }
    this.props.onFilesUpload(files, callback);
  }

  filesEdit(file, callback) {
    if (!this.props.onFilesEdit) {
      return;
    }
    this.props.onFilesEdit(file, callback);
  }

  filesDelete(files, callback) {
    if (!this.props.onFilesDelete) {
      return;
    }
    this.props.onFilesDelete(files, callback);
  }

  deleteTask(callback) {
    if (!this.props.onTaskDelete) {
      return;
    }
    this.props.onTaskDelete(callback);
  }

  save() {
    if (!this.props.onSave) {
      return;
    }
    this.setState({
      taskSaving: true,
    }, () => {
      this.props.onSave(() => {
        this.setState({
          taskSaving: false,
        });
      });
    });
  }

  publish() {
    if (!this.props.onPublish) {
      return;
    }
    const options = {
      importable: this.state.importable === undefined ? this.props.userTask.data.importable !== false : this.state.importable,
      distributable: this.state.distributable === undefined ? this.props.userTask.data.distributable === true : this.state.distributable,
      public: this.state.public === undefined ? this.props.userTask.data.public === true : this.state.public,
      allowAnonymous: this.state.allowAnonymous === undefined ? this.props.userTask.data.allowAnonymous === true : this.state.allowAnonymous,
      creatorLogType: this.state.creatorLogType === undefined ? (this.props.userTask.data.creatorLogType || DEFAULT_CREATOR_LOG_TYPE) : this.state.creatorLogType,
      authorLogType: this.state.authorLogType === undefined ? (this.props.userTask.data.authorLogType || DEFAULT_AUTHOR_LOG_TYPE) : this.state.authorLogType,
      creatorId: (this.state.showCreator === undefined ? (this.props.userTask.data.creatorId !== null && this.props.userTask.data.creatorId !== undefined) : this.state.showCreator) ? this.props.userTask.uid : null,
    };
    this.setState({
      taskSaving: true,
    }, () => {
      this.props.onPublish(options, () => {
        this.setState({
          taskSaving: false,
        });
      });
    });
  }

  updateShares(props, callback) {
    if (!this.props.onSharesUpdate) {
      return;
    }
    this.props.onSharesUpdate(props, callback);
  }

  downloadQRCode() {
    if (!this.props.onDownloadQRCode) {
      return;
    }
    this.props.onDownloadQRCode();
  }

  needToDeploy() {
    if (this.state.importable !== undefined && this.state.importable !== (this.props.userTask.data.importable !== false)) {
      return true;
    }
    if (this.state.distributable !== undefined && this.state.distributable !== (this.props.userTask.data.distributable === true)) {
      return true;
    }
    if (this.state.public !== undefined && this.state.public !== (this.props.userTask.data.public === true)) {
      return true;
    }
    if (this.state.allowAnonymous !== undefined && this.state.allowAnonymous !== (this.props.userTask.data.allowAnonymous === true)) {
      return true;
    }
    if (this.state.creatorLogType !== undefined && this.state.creatorLogType !== (this.props.userTask.data.creatorLogType || DEFAULT_CREATOR_LOG_TYPE)) {
      return true;
    }
    if (this.state.authorLogType !== undefined && this.state.authorLogType !== (this.props.userTask.data.authorLogType || DEFAULT_AUTHOR_LOG_TYPE)) {
      return true;
    }
    if (this.state.showCreator !== undefined &&
      this.state.showCreator !== (this.props.userTask.data.creatorId !== null && this.props.userTask.data.creatorId !== undefined)) {
      return true;
    }
    const deployed = this.props.userTask.data.deployed || 0;
    return this.props.userTask.data.updated > deployed;
  }

  hasPolicyError() {
    const showCreatorChecked = this.state.showCreator === undefined ?
      (this.props.userTask.data.creatorId !== null && this.props.userTask.data.creatorId !== undefined) :
      this.state.showCreator;
    if (showCreatorChecked && !this.props.creatorUserInfo) {
      return true;
    }
    if (this.props.baseTask && !showCreatorChecked) {
      return true;
    }
    const distributable = this.state.distributable === undefined ? this.props.userTask.data.distributable === true : this.state.distributable;
    if (distributable && !showCreatorChecked) {
      return true;
    }
    if (distributable && !this.props.userTask.data.description) {
      return true;
    }
    return false;
  }

  getTaskUrl() {
    const { taskId } = this.props.userTask.data;
    return `${window.location.origin}/t/${taskId}`;
  }

  computeEditorHeight() {
    const FIXED_MARGIN = 4;
    const toolbarHeight = (this.toolbarRef.current && this.toolbarRef.current.clientHeight) || 0;
    const editorBarHeight = (this.editorBarRef.current && this.editorBarRef.current.clientHeight) || 0;
    const statusBarHeight = (this.statusBarRef.current && this.statusBarRef.current.clientHeight) || 0;
    const maxHeight = this.props.height - toolbarHeight - editorBarHeight - statusBarHeight - FIXED_MARGIN;
    return this.state.taskFullscreen ? maxHeight : '40vh';
  }

  notifyCreatorPolicyChanged() {
    if (!this.props.onCreatorPolicyChanged) {
      return;
    }
    this.props.onCreatorPolicyChanged({
      showCreator: this.state.showCreator,
      creatorLogType: this.state.creatorLogType,
    })
  }

  notifyAuthorPolicyChanged() {
    if (!this.props.onAuthorPolicyChanged) {
      return;
    }
    this.props.onAuthorPolicyChanged({
      authorLogType: this.state.authorLogType,
    });
  }

  changeShowCreator(showCreator) {
    this.setState({
      showCreator,
    }, () => this.notifyCreatorPolicyChanged());
  }

  renderScriptEditor() {
    const { classes, intl } = this.props;
    return <Container className={classes.editorPanel}>
      {this.props.lastEvalError &&
        <Container className={classes.lastEvalError}>
          {this.props.lastEvalError.toString()}
        </Container>}
      <AceEditor
        placeholder={intl.formatMessage({ id: 'placeholderScript' })}
        mode="javascript"
        theme="github"
        name="blah2"
        onLoad={(editor) => {
          this.scriptEditor = editor;
        }}
        onChange={(newValue) => this.scriptChange(newValue)}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        width='100%'
        height={this.computeEditorHeight()}
        value={this.props.userTask.data.script || ''}
        annotations={this.state.scriptErrors || []}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
        }}
        commands={[{
          name: 'save',
          bindKey: {win: 'Ctrl-S', mac: 'Cmd-S'},
          exec: (editor) => {
            this.scriptChange(editor.session.getValue());
            this.save();
          }
        }]}
      />
    </Container>;
  }

  renderUsageView() {
    const { classes, baseTask } = this.props;
    const height = this.computeEditorHeight();
    return <Container className={classes.editorPanel}>
      <Container style={{ height }}>
        <ReactMarkdown>
          {baseTask.data.description}
        </ReactMarkdown>
      </Container>
    </Container>;
  }

  renderParamEditor() {
    const { classes, baseTask } = this.props;
    const height = this.computeEditorHeight();
    const baseParamSchema = baseTask ? (baseTask.data.paramschema || []) : null;
    return <Container className={classes.editorPanel}>
      <Container style={{ height }}>
        <ParamEditor
          editable={true}
          schemaEditable={baseParamSchema === null}
          systemSchema={this.props.systemParamSchema || []}
          schema={baseParamSchema || this.props.userTask.data.paramschema || []}
          value={this.props.userTask.data.param || []}
          panelHeight={height}
          onChange={(newValue) => this.paramChange(newValue)}
          onSchemaChange={(newValue) => this.paramSchemaChange(newValue)}
        />
      </Container>
    </Container>
  }

  renderDescriptionEditor() {
    const { classes, intl } = this.props;
    return <Container className={classes.editorPanel}>
      <AceEditor
        placeholder={intl.formatMessage({ id: 'placeholderDescription' })}
        mode="markdown"
        theme="github"
        name="blah2"
        onChange={(newValue) => this.descriptionChange(newValue)}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        width='100%'
        height={this.computeEditorHeight()}
        value={this.props.userTask.data.description || ''}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
        }}
        commands={[{
          name: 'save',
          bindKey: {win: 'Ctrl-S', mac: 'Cmd-S'},
          exec: (editor) => {
            this.descriptionChange(editor.session.getValue());
            this.save();
          }
        }]}
      />
    </Container>;
  }

  renderFileEditor() {
    const { classes } = this.props;
    const height = this.computeEditorHeight();
    return <Container className={classes.editorPanel}>
      <Container style={{ height }}>
        <FileListEditor
          editable={true}
          sizeLimit={this.props.permission ? {
            current: this.props.permission.current,
            capacity: this.props.permission.capacity,
          } : {}}
          files={this.props.files}
          baseFilePath={this.props.baseFilePath}
          panelHeight={height}
          onDrop={(files, callback) => this.filesDrop(files, callback)}
          onEdit={(file, callback) => this.filesEdit(file, callback)}
          onDelete={(files, callback) => this.filesDelete(files, callback)}
        />
      </Container>
    </Container>
  }

  renderDebugger() {
    const { classes } = this.props;
    const height = this.computeEditorHeight();
    return <Container className={classes.editorPanel}>
      <Debugger
        height={height}
        lines={this.props.debugLines}
      />
    </Container>
  }

  renderCreatorPolicy() {
    const { classes, baseTask } = this.props;
    const showCreatorChecked = this.state.showCreator === undefined ?
      (this.props.userTask.data.creatorId !== null && this.props.userTask.data.creatorId !== undefined) :
      this.state.showCreator;
    return <>
      <FormControlLabel
        control={
          <Switch
            checked={showCreatorChecked}
            onChange={(event) => this.changeShowCreator(event.target.checked)}
            color="primary"
          />
        }
        label={<FormattedMessage id='taskShowCreator'/>}
      />
      {showCreatorChecked && !this.props.creatorUserInfo && <div className={classes.policyError}>
        <FormattedMessage id='userInfoNotDefined' />
        <a href='/settings'><FormattedMessage id='currentUser'/></a>
      </div>}
      {baseTask && !showCreatorChecked && <div className={classes.policyError}>
        <FormattedMessage id='distributorInfoNotShown' />
      </div>}
    </>
  }

  renderDeployEditor() {
    const { classes } = this.props;
    const distributable = this.state.distributable === undefined ? this.props.userTask.data.distributable === true : this.state.distributable;
    const showCreatorChecked = this.state.showCreator === undefined ?
      (this.props.userTask.data.creatorId !== null && this.props.userTask.data.creatorId !== undefined) :
      this.state.showCreator;
    return <Container className={classes.editorPanel}>
      <Container className={classes.deployPanel} style={{ height: this.computeEditorHeight() }}>
        <Typography variant="h4" component="h4" className={classes.deployHeader}>
          <FormattedMessage id='deployPublish' />
          {this.needToDeploy() && <strong className={classes.needToDeploy}>
              <FormattedMessage id='hasUnpublishedChanges' />
            </strong>}
        </Typography>
        <p>
          <FormattedMessage id='descriptionPublish' />
        </p>
        <div className={classes.deployParam}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.public === undefined ? this.props.userTask.data.public === true : this.state.public}
                onChange={(event) => this.setState({ public: event.target.checked })}
                color="primary"
              />
            }
            label={<FormattedMessage id='taskPublic'/>}
          />
        </div>
        <div className={classes.deployParam}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.allowAnonymous === undefined ? this.props.userTask.data.allowAnonymous === true : this.state.allowAnonymous}
                onChange={(event) => this.setState({ allowAnonymous: event.target.checked })}
                color="primary"
              />
            }
            label={<FormattedMessage id='taskAllowAnonymous'/>}
          />
        </div>
        <Typography variant="h5" component="h5" className={classes.deploySubHeader}>
          <FormattedMessage id='headerTaskReuse' />
        </Typography>
        <div className={classes.deployParam}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.importable === undefined ? this.props.userTask.data.importable !== false : this.state.importable}
                onChange={(event) => this.setState({ importable: event.target.checked })}
                color="primary"
              />
            }
            label={<FormattedMessage id='taskImportable'/>}
          />
        </div>
        {!this.props.baseTask && <div className={classes.deployParam}>
            <FormControlLabel
              control={
                <Switch
                  checked={distributable}
                  onChange={(event) => this.setState({ distributable: event.target.checked })}
                  color="primary"
                />
              }
              label={<FormattedMessage id='taskDistributable'/>}
            />
            {distributable && !showCreatorChecked && <div className={classes.policyError}>
              <FormattedMessage id='authorInfoNotShown' />
            </div>}
            {distributable && !this.props.userTask.data.description && <div className={classes.policyError}>
              <FormattedMessage id='authorDescriptionNotDefined' />
            </div>}
          </div>}
        {distributable && <div className={classes.deployParam}>
          <FormControl>
            <InputLabel id='author-log-type'>
              <FormattedMessage id='taskAuthorLogType'/>
            </InputLabel>
            <Select
              labelId='creator-log-type-label'
              value={this.state.authorLogType === undefined ?
                (this.props.userTask.data.authorLogType || DEFAULT_AUTHOR_LOG_TYPE) : this.state.authorLogType}
              onChange={(event) => this.setState(
                  { authorLogType: event.target.value },
                  () => this.notifyAuthorPolicyChanged()
                )}
              color="primary"
            >
              <MenuItem value='pseudonymized'><FormattedMessage id='taskAuthorPseudonymizedLogType' /></MenuItem>
              <MenuItem value='none'><FormattedMessage id='taskAuthorNoneLogType' /></MenuItem>
            </Select>
          </FormControl>
        </div>}
        <Typography variant="h5" component="h5" className={classes.deploySubHeader}>
          <FormattedMessage id='headerTaskCreator' />
        </Typography>
        <div className={classes.deployParam}>
          {this.renderCreatorPolicy()}
        </div>
        <div className={classes.deployParam}>
          <FormControl>
            <InputLabel id='creator-log-type'>
              <FormattedMessage id='taskCreatorLogType'/>
            </InputLabel>
            <Select
              labelId='creator-log-type-label'
              value={this.state.creatorLogType === undefined ?
                (this.props.userTask.data.creatorLogType || DEFAULT_CREATOR_LOG_TYPE) : this.state.creatorLogType}
              onChange={(event) => this.setState(
                  { creatorLogType: event.target.value },
                  () => this.notifyCreatorPolicyChanged()
                )}
              color="primary"
            >
              <MenuItem value='pseudonymized'><FormattedMessage id='taskPseudonymizedLogType' /></MenuItem>
              <MenuItem value='none'><FormattedMessage id='taskNoneLogType' /></MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          data-testid='publish'
          variant="contained"
          color="secondary"
          className={classes.deployButton}
          disabled={!this.needToDeploy() || this.hasPolicyError() || this.state.taskSaving}
          onClick={() => this.publish()}
        >
          <CloudUploadIcon />
          <FormattedMessage id="taskPublish"/>
        </Button>
        <Typography variant="h4" component="h4" className={classes.deployHeader}>
          <FormattedMessage id='deployLink' />
        </Typography>
        <p>
          <FormattedMessage id='descriptionLink' />
        </p>
        <Typography variant="caption" component="caption" className={classes.taskUrl}>
          {this.getTaskUrl()}
          <CopyToClipboard text={this.getTaskUrl()} onCopy={() => this.setState({ copied: true })}>
            <FileCopyIcon />
          </CopyToClipboard>
        </Typography>
        {this.state.copied && <div className={classes.urlCopied}>
            <FormattedMessage id='urlCopied' />
          </div>}
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.downloadQRCode()}
        >
          <GetAppIcon />
          <FormattedMessage id="downloadQRCode"/>
        </Button>
        {!this.props.userTask.shared && <>
          <Typography variant="h4" component="h4" className={classes.deployHeader}>
            <FormattedMessage id='manageTask' />
          </Typography>
          <p>
            <FormattedMessage id='descriptionDeleteTask' />
          </p>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.setState({ confirmDelete: true })}
          >
            <DeleteIcon />
            <FormattedMessage id="deleteTask"/>
          </Button>
        </>}
      </Container>
    </Container>;
  }

  renderEditors() {
    const { classes } = this.props;
    const editorTab = this.state.editorTab || 0;
    return <div className={classes.editors}>
      <AppBar position="static" color="default" ref={this.editorBarRef}>
        <Tabs
          value={editorTab}
          onChange={(event, newValue) => this.setState({
            editorTab: newValue,
            debugLinesUpdated: newValue === 3 ? false : this.state.debugLinesUpdated,
          })}
        >
          {!this.props.baseTask && <Tab icon={<CodeIcon />} label={<FormattedMessage id="scriptTab"/>} />}
          {this.props.baseTask && <Tab icon={<CommentIcon />} label={<FormattedMessage id="usageTab"/>} />}
          <Tab icon={<ViewListIcon />} label={<FormattedMessage id="paramTab"/>} />
          <Tab icon={<DescriptionIcon />} label={<FormattedMessage id="descriptionTab"/>} />
          <Tab icon={<Badge badgeContent={this.state.debugLinesUpdated ? 1 : 0} color='secondary'>
              <BugReportIcon />
            </Badge>} label={<FormattedMessage id="debugTab"/>} />
          <Tab
            icon={<AttachFileIcon />}
            label={<FormattedMessage id="fileTab"/>} />
          <Tab icon={<Badge badgeContent={this.needToDeploy() ? 1 : 0} color='secondary'>
              <CloudUploadIcon />
            </Badge>} label={<FormattedMessage id="deployTab"/>} />
        </Tabs>
      </AppBar>
      {!this.props.baseTask && editorTab === 0 &&
        this.renderScriptEditor()}
      {this.props.baseTask && editorTab === 0 &&
        this.renderUsageView()}
      {editorTab === 1 &&
        this.renderParamEditor()}
      {editorTab === 2 &&
        this.renderDescriptionEditor()}
      {editorTab === 3 &&
        this.renderDebugger()}
      {editorTab === 4 &&
        this.renderFileEditor()}
      {editorTab === 5 &&
        this.renderDeployEditor()}
      <Container className={classes.statusBar} ref={this.statusBarRef}>
        {editorTab === 0 && <>
            <FormattedMessage id="descriptionScriptTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/JavaScript'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
        {editorTab === 1 && <>
            <FormattedMessage id="descriptionParamTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/Param'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
        {editorTab === 2 && <>
            <FormattedMessage id="descriptionDescriptionTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/Markdown'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
        {editorTab === 3 && <>
            <FormattedMessage id="descriptionDebugTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/Debugger'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
        {editorTab === 4 && <>
            <FormattedMessage id="descriptionFileTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/Files'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
        {editorTab === 5 && <>
            <FormattedMessage id="descriptionDeployTab"/>
            <a
              className={classes.helpIcon}
              href='https://scrapbox.io/cogtask-me/Deployment'
              target='_blank'
              rel='noreferrer'>
              <HelpIcon />
            </a>
          </>}
      </Container>
    </div>
  }

  renderToolButtons() {
    return <>
      <Button
        variant="contained"
        color="primary"
        disabled={this.state.taskSaving}
        onClick={() => this.save()}
      >
        <SaveIcon />
        <FormattedMessage id="taskSave"/>
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={this.state.taskSaving}
        onClick={() => this.setState({ share: true })}
      >
        <PeopleIcon />
        <FormattedMessage id="taskShare"/>
      </Button>
    </>
  }

  render() {
    const { classes } = this.props;
    return <Paper elevation={1} className={this.state.taskFullscreen ? classes.fullscreenRoot : classes.root}>
      <Container className={classes.toolbar} ref={this.toolbarRef}>
        <NameLabel
          value={this.props.userTask.data.title || ''}
          editable={this.state.taskOpen}
          onChange={(newValue) => this.titleChange(newValue)}
        />
        {this.state.taskOpen && this.renderToolButtons()}
        <Button
          variant="contained"
          color="default"
          className={classes.close}
          onClick={() => this.setState({
            taskOpen: !this.state.taskOpen,
            taskFullscreen: false
          })}
        >
          {this.state.taskOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          <FormattedMessage id={this.state.taskOpen ? "taskClose" : "taskOpen"}/>
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.fullscreen}
          onClick={() => this.setState({
            taskFullscreen: !this.state.taskFullscreen,
            taskOpen: true
          })}
        >
          {this.state.taskFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          <FormattedMessage id={this.state.taskFullscreen ? "taskFullscreenExit" : "taskFullscreen"}/>
        </Button>
      </Container>
      {this.state.taskOpen &&
        this.renderEditors()}
      <DeleteConfirmDialog
        open={this.state.confirmDelete}
        title={this.props.userTask.data.title || ''}
        onCancel={() => this.setState({ confirmDelete: false })}
        onPerform={(callback) => this.deleteTask(callback)}
      />
      <ShareDialog
        open={this.state.share}
        userTask={this.props.userTask}
        onSharesUpdate={(props, callback) => this.updateShares(props, callback)}
        onClose={() => this.setState({ share: false })}
      />
    </Paper>
  }
}

export default withStyles(styles)(withResizeDetector(injectIntl(TaskEditor)))
