import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from "@material-ui/styles";
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import BeforeUnloadComponent from 'react-beforeunload-component';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import ClipLoader from "react-spinners/ClipLoader";
import safeEval from "safe-eval";
import $ from "jquery";
import { LogLevel } from "./Debugger";
import Loading from './Loading';
import LoadingDialog from "./LoadingDialog";
import Author from "./Author";
import UserStorage from "./task/UserStorage";

const styles = (theme) => ({
  root: {
    fontSize: '1.2em',
  },
  title: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
  startButton: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  reloadButton: {
    position: 'absolute',
    top: '7em',
    right: '5em',
  },
  description: {
    textAlign: 'left',
    wordBreak: 'keep-all',
    overflowWrap: 'break-word',
  },
  result: {
    textAlign: 'center',
  },
  author: {
    fontSize: '0.9em',
  },
  creatorLogTypeInfo: {
    color: 'gray',
  },
  creatorLogTypeNotice: {
    fontWeight: 'bold',
  },
  authorPanel: {
    marginTop: '1em',
  },
});

function fileEquals(file1, file2) {
  if (file1.id !== file2.id) {
    return false;
  }
  if (file1.data.name !== file2.data.name) {
    return false;
  }
  if (file1.data.importType !== file2.data.importType) {
    return false;
  }
  if (file1.data.preload !== file2.data.preload) {
    return false;
  }
  if (file1.data.priority !== file2.data.priority) {
    return false;
  }
  return true;
}

function arrayEquals(files1, files2) {
  if (files1.length !== files2.length) {
    return false;
  }
  return files1.every((file, fileIndex) => fileEquals(files2[fileIndex], file));
}

function compareFile(file1, file2) {
  const priority1 = parseInt(file1.data.priority || 100);
  const priority2 = parseInt(file2.data.priority || 100);
  if (priority1 > priority2) {
    return -1;
  }
  if (priority1 < priority2) {
    return 1;
  }
  if (file1.data.name < file2.data.name) {
    return -1;
  }
  if (file1.data.name > file2.data.name) {
    return 1;
  }
  return 0;
}

class Console {
  constructor(task) {
    this.task = task;
  }

  log(msg, ...args) {
    this.task.debug(LogLevel.DEBUG, msg, ...args);
  }

  info(msg, ...args) {
    this.task.debug(LogLevel.INFO, msg, ...args);
  }

  warn(msg, ...args) {
    this.task.debug(LogLevel.WARN, msg, ...args);
  }

  error(msg, ...args) {
    this.task.debug(LogLevel.ERROR, msg, ...args);
  }
}

class AppMenu {
  constructor(task) {
    this.task = task;
  }

  show() {
    const {onAppMenuShow} = this.task.props;
    if (!onAppMenuShow) {
      return;
    }
    onAppMenuShow();
  }

  hide() {
    const {onAppMenuHide} = this.task.props;
    if (!onAppMenuHide) {
      return;
    }
    onAppMenuHide();
  }
}

class Agreement {
  constructor(agreement, userAgreement) {
    this.agreed = userAgreement && userAgreement.exists && userAgreement.data.agree;
    this.url = userAgreement ? `/agreement/${userAgreement.taskId}` : null;
  }
}

class LTI {
  constructor(props) {
    this.props = props;
  }

  onGradeUpdate(callback) {
    const { onLTIGradeUpdateHandlerAdd } = this.props;
    if (!onLTIGradeUpdateHandlerAdd) {
      throw new Error('No LTI module');
    }
    onLTIGradeUpdateHandlerAdd(callback);
  }

  getLoginURL(custom, callback) {
    const { onLTILoginURLRequest } = this.props;
    if (!onLTILoginURLRequest) {
      throw new Error('No LTI module');
    }
    if (!callback) {
      return new Promise((resolve, reject) => {
        onLTILoginURLRequest(custom, resolve, reject);
      });
    }
    onLTILoginURLRequest(custom,
      (result) => {
        callback(result, null);
      },
      (error) => {
        callback(null, error);
      })
  }
}

class Messaging {
  constructor(props) {
    this.props = props;
  }

  getLastResult(callback) {
    const { onMessagingLastResultGet } = this.props;
    if (!onMessagingLastResultGet) {
      throw new Error('No messaging module');
    }
    onMessagingLastResultGet(callback);
  }

  request(options, callback) {
    const { onMessagingRequest } = this.props;
    if (!onMessagingRequest) {
      throw new Error('No messaging module');
    }
    onMessagingRequest(options, callback);
  }

  setSchedule(options, callback) {
    const { onMessagingSetSchedule } = this.props;
    if (!onMessagingSetSchedule) {
      throw new Error('No messaging module');
    }
    onMessagingSetSchedule(options, callback);
  }

  clearSchedule(callback) {
    const { onMessagingClearSchedule } = this.props;
    if (!onMessagingClearSchedule) {
      throw new Error('No messaging module');
    }
    onMessagingClearSchedule(callback);
  }

  send(message, callback) {
    const { onMessagingMessageSend } = this.props;
    if (!onMessagingMessageSend) {
      throw new Error('No messaging module');
    }
    onMessagingMessageSend(message, callback);
  }
}

class Health {
  constructor(props) {
    this.props = props;
  }

  getLastResult(callback) {
    const { onHealthLastResultGet } = this.props;
    if (!onHealthLastResultGet) {
      throw new Error('No health module');
    }
    onHealthLastResultGet(callback);
  }

  request(options, callback) {
    const { onHealthRequest } = this.props;
    if (!onHealthRequest) {
      throw new Error('No health module');
    }
    onHealthRequest(options, callback);
  }

  setHandler(options, callback) {
    const { onHealthHandlerSet } = this.props;
    if (!onHealthHandlerSet) {
      throw new Error('No health module');
    }
    onHealthHandlerSet(options, callback);
  }

  clearHandler(callback) {
    const { onHealthHandlerClear } = this.props;
    if (!onHealthHandlerClear) {
      throw new Error('No health module');
    }
    onHealthHandlerClear(callback);
  }

  getLastData(query, callback) {
    const { onHealthLastDataGet } = this.props;
    if (!onHealthLastDataGet) {
      throw new Error('No health module');
    }
    onHealthLastDataGet(query, callback);
  }
}

class Task extends Component {
  constructor(props) {
    super(props);

    this.state = {
      started: false,
      changed: false,
      finishing: false,
      lastPreloadFiles: null,
      finished: false,
      result: null,
      loading: null,
      waitForLink: false,
      windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
    };
    this.mainRef = React.createRef();
    this.timerIds = [];
    this.handleResize = () => this.setState({
      windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
    });
    this.preloadFiles = React.createRef();
  }

  componentDidMount() {
    console.log('Task mounted', this.props);
    this.checkPreloadFilesUpdated();
    setTimeout(() => this.checkAutoStart(), 100);
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    this.resetTimers();
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('Task updated', this.props, prevProps);
    this.checkScriptUpdated(prevProps);
    this.checkPreloadFilesUpdated(prevProps);
    setTimeout(() => this.checkAutoStart(), 100);
  }

  checkAutoStart() {
    if (this.isLoading()) {
      return;
    }
    if (this.state.started) {
      return;
    }
    if (!this.isAutoStart()) {
      return;
    }
    this.start();
  }

  isAutoStart() {
    if (!this.props.allowAutoStart) {
      return false;
    }
    const { param } = this.props;
    const autoStartParam = (param || [])
      .filter((param) => param.name === 'GOEMON_AUTO_START')[0];
    if (!autoStartParam) {
      return false;
    }
    return autoStartParam.value === 'true';
  }

  checkScriptUpdated(prevProps) {
    if (prevProps.script === this.props.script) {
      return;
    }
    setTimeout(() => {
      this.reload(true);
    }, 10);
  }

  isPreloadType(file) {
    return file.data.importType === 'javascript' || file.data.importType === 'css' || file.data.importType === 'image';
  }

  createPreloadElement(file, callback) {
    if (file.data.importType === 'javascript') {
      return this.createScriptElement(file, callback);
    }
    if (file.data.importType === 'css') {
      return this.createCSSElement(file, callback);
    }
    if (file.data.importType === 'image') {
      return this.createImageElement(file, callback);
    }
    throw new Error(`Unknown type: ${file.data.importType}`);
  }

  createScriptElement(file, callback) {
    console.log('Creating script element', file);
    const script = document.createElement('script');
    script.id = `task-script-${file.id}`;
    script.src = this.getFileURLFromFile(file);
    script.async = true;
    script.onload = () => {
      this.resourceLoaded(file);
      if (!callback) {
        return;
      }
      callback();
    };
    script.onerror = () => {
      this.resourceFailed(file);
      if (!callback) {
        return;
      }
      callback();
    };
    return {
      file,
      element: script,
      parent: document.head,
    };
  }

  createCSSElement(file, callback) {
    const link = document.createElement('link');
    link.id = `task-style-${file.id}`;
    link.rel = 'stylesheet';
    link.href = this.getFileURLFromFile(file);
    link.onload = () => {
      this.resourceLoaded(file);
      if (!callback) {
        return;
      }
      callback();
    };
    return {
      file,
      element: link,
      parent: document.head,
    };
  }

  createImageElement(file, callback) {
    const { intl } = this.props;
    this.debug(LogLevel.INFO, intl.formatMessage({ id: 'debugPreloadStarting' }), file.data.name);
    const image = document.createElement('img');
    image.src = this.getFileURLFromFile(file);
    image.onload = () => {
      this.resourceLoaded(file);
      if (!callback) {
        return;
      }
      callback();
    };
    image.onerror = () => {
      this.resourceFailed(file);
      if (!callback) {
        return;
      }
      callback();
    };
    return {
      file,
      element: image,
      parent: null, //document.body,
    };
  }

  checkPreloadFilesUpdated(prevProps) {
    if (this.preloadFiles.current !== null &&
      arrayEquals((prevProps || {}).files || [], this.props.files || [])) {
      return;
    }
    const preloadFiles = {};
    const files = (this.props.files || []).filter((file) => file.data.preload && this.isPreloadType(file));
    files.sort(compareFile);
    files.forEach((file) => {
      preloadFiles[file.id] = { loaded: false, priority: file.data.priority || 100 };
    });
    const removeIds = Object.keys(this.preloadFiles.current || {}).filter((fileId) => !this.preloadFiles.current[fileId]);
    this.preloadFiles.current = preloadFiles;

    this.setState({
      lastPreloadFiles: preloadFiles,
    }, () => {
      removeIds.forEach((removeId) => {
        const removeElem = document.getElementById(removeId);
        if (removeElem) {
          removeElem.parentNode.removeChild(removeElem);
        }
      });
      console.log('Preload files', files, preloadFiles);
      this.preloadElements({ files });
    });
  }

  preloadElements(files) {
    const { intl } = this.props;
    const preloadFiles = this.preloadFiles.current || {};
    console.log('Preloading progress...', files, preloadFiles);
    let remainElements = files.files;
    while (remainElements.length > 0) {
      const file = remainElements[0];
      if (this.hasPreloadingFiles(file.data.priority || 100)) {
        break;
      }
      const { parent, element } = this.createPreloadElement(file, () => {
        this.preloadElements(files);
      })
      if (parent) {
        this.debug(LogLevel.INFO, intl.formatMessage({ id: 'debugPreloadStarting' }), file.data.name);
        const oldElem = document.getElementById(element.id);
        if (oldElem) {
          oldElem.parentNode.removeChild(oldElem);
        }
        parent.appendChild(element);
      }
      remainElements = remainElements.slice(1);
    }
    files.files = remainElements;
  }

  hasPreloadingFiles(priority) {
    const preloadFiles = this.preloadFiles.current || {};
    const preloadings = Object.values(preloadFiles)
      .filter((file) => !file.loaded && parseInt(file.priority) > parseInt(priority));
    return preloadings.length > 0;
  }

  setPreloaded(file) {
    const preloadFiles = this.preloadFiles.current || {};
    const newPreloadFiles = {};
    Object.keys(preloadFiles).forEach((fileId) => {
      const fileEntry = Object.assign({}, preloadFiles[fileId]);
      if (fileId === file.id) {
        fileEntry.loaded = true;
      }
      newPreloadFiles[fileId] = fileEntry;
    })
    this.preloadFiles.current = newPreloadFiles;
    this.setState({
      lastPreloadFiles: newPreloadFiles
    });
    console.log('Preload files updated', newPreloadFiles);
  }

  resourceLoaded(file) {
    const { intl } = this.props;
    this.debug(LogLevel.INFO, intl.formatMessage({ id: 'debugPreloadFinished' }), file.data.name);
    this.setPreloaded(file);
  }

  resourceFailed(file) {
    const { intl } = this.props;
    this.debug(LogLevel.ERROR, intl.formatMessage({ id: 'debugPreloadFailed' }), file.data.name);
    this.setPreloaded(file);
  }

  start() {
    this.setState({
      started: true,
      finished: false,
      finishing: false,
      changed: false,
      waitForLink: false,
    }, () => {
      if (this.props.onStart) {
        this.props.onStart();
      }
      this.evalScript();
      setTimeout(() => {
        if (!this.mainRef.current) {
          return;
        }
        this.mainRef.current.focus();
      }, 10);
    });
  }

  getFileURL(filename) {
    console.log('Files', this.props.files);
    const files = (this.props.files || []).filter((file) => file.data.name === filename);
    if (files.length === 0) {
      throw new Error(`File not found: ${filename}`);
    }
    return this.getFileURLFromFile(files[0]);
  }

  getFileURLFromFile(file) {
    const filename = encodeURIComponent(file.data.name);
    const extMatch = filename.match(/^[^.]+(\..+)$/);
    const ext = extMatch ? extMatch[1] : '';
    const url = `${this.props.baseURL || ''}/api/v1/${file.basePath}files/${file.id}${ext}?n=${filename}`;
    return url;
  }

  debug(level, message, ...args) {
    console.log('DEBUG', level, message, ...args);
    if (!this.props.onDebug) {
      return;
    }
    this.props.onDebug(level, message, ...args);
  }

  getParamValue(param) {
    const schema = (this.props.paramSchema || []).concat(this.props.systemParamSchema || []);
    const target = schema.filter((s) => s.name === param.name);
    if (target.length === 0) {
      return param.value;
    }
    const targetType = target[target.length - 1].type;
    if (targetType === 'integer') {
      return parseInt(param.value);
    }
    if (targetType === 'number') {
      return parseFloat(param.value);
    }
    if (targetType === 'boolean') {
      return param.value === 'true';
    }
    return param.value;
  }

  getParam() {
    const r = {};
    (this.props.param || []).forEach((p) => {
      r[p.name] = this.getParamValue(p)
    });
    return r;
  }

  evalScript() {
    const console = new Console(this);
    const appMenu = new AppMenu(this);
    const taskContext = this.props.context;
    const param = this.getParam();
    Object.freeze(param);
    const search = new URLSearchParams(this.props.search || window.location.search);
    const lti = new LTI(this.props);
    const agreement = new Agreement(this.props.agreement, this.props.userAgreement);
    const messaging = new Messaging(this.props);
    const health = new Health(this.props);
    const userStorage = new UserStorage(this.props);
    const context = {
      $,
      setTimeout: (callback, ...args) => {
        const timerId = setTimeout(() => {
          if (!this.state.started) {
            return;
          }
          try {
            callback();
          } catch(e) {
            this.showError('errorEval', e);
          }
        }, ...args);
        this.timerIds.push(timerId);
        return timerId;
      },
      setInterval: (callback, ...args) => {
        const timerId = setInterval(() => {
          if (!this.state.started) {
            return;
          }
          try {
            callback();
          } catch(e) {
            this.showError('errorEval', e);
          }
        }, ...args);
        this.timerIds.push(timerId);
        return timerId;
      },
      clearTimeout: (timeoutID) => {
        this.timerIds = this.timerIds.filter((id) => id !== timeoutID);
        clearTimeout(timeoutID);
      },
      clearInterval: (timeoutID) => {
        this.timerIds = this.timerIds.filter((id) => id !== timeoutID);
        clearInterval(timeoutID);
      },
      alert: (text) => {
        alert(text);
      },
      console,
      context: {
        root: $("#main"),
        taskURL: this.props.taskURL,
        appMenu,
        pseudonymUserId: (taskContext || {}).userId || null,
        groupId: (taskContext || {}).groupId || null,
        userEmailDomain: this.props.userEmailDomain || null,
        finish: (summary, log, options) => {
          this.finish(summary, log, options);
        },
        abort: (options) => {
          this.setFinished(null, options);
        },
        log: (summary, log, callback) => {
          this.log(summary, log, callback);
        },
        getGlobal: (name) => {
          return window[name];
        },
        getFileURL: (filename) => this.getFileURL(filename),
        createImage: (filename) => {
          return $('<img/>').attr('src', this.getFileURL(filename));
        },
        console,
        param,
        search,
        lti,
        agreement,
        messaging,
        health,
        userStorage,
      },
    };
    try {
      safeEval('false;' + this.props.script, context);
    } catch(e) {
      this.showError('errorEval', e);
    }
  }

  needAgreement() {
    const { agreement, userAgreement } = this.props;
    if (!agreement) {
      return false;
    }
    if (!agreement.required) {
      return false;
    }
    if (userAgreement.exists && userAgreement.data.agree) {
      return false;
    }
    return true;
  }

  hasAgreement() {
    const { agreement, userAgreement } = this.props;
    if (!agreement) {
      return false;
    }
    if (userAgreement.exists && userAgreement.data.agree) {
      return true;
    }
    return false;
  }

  openAgreement() {
    const { onAgreementOpen } = this.props;
    if (!onAgreementOpen) {
      return;
    }
    onAgreementOpen();
  }

  showError(message, error) {
    if (!this.props.onError) {
      return;
    }
    this.props.onError(message, error);
  }

  finish(summary, log, options) {
    if (!this.state.started) {
      return;
    }
    this.resetTimers();
    if (summary === undefined) {
      throw new Error('summary is not set: `context.finish(summary, log)`');
    }
    if (log === undefined) {
      throw new Error('log is not set: `context.finish(summary, log)`');
    }
    this.setState({
      finishing: true,
      finished: false,
    }, () => {
      if (this.props.onAppMenuShow) {
        this.props.onAppMenuShow();
      }
      if (!this.props.onFinish) {
        return;
      }
      this.props.onFinish(summary, log, () => {
        this.setFinished({
          summary, log,
        }, options);
      });
    })
  }

  setFinished(result, options) {
    this.resetTimers();
    const onCompleted = (options && options.onCompleted) || null;
    if (onCompleted) {
      try {
        onCompleted();
      } catch(err) {
        this.showError('errorEvalHandler', err);
      }
    }
    const nextLink = (options && options.next) || null;
    if (nextLink && nextLink.force) {
      this.setState({
        finishing: false,
        finished: true,
        waitForLink: true,
      }, () => setTimeout(() => this.openUserLink(nextLink.url), 500));
      return;
    }
    this.setState({
      finishing: false,
      finished: true,
      waitForLink: false,
      result,
      nextLink,
    });
  }

  openUserLink(url) {
    if (!this.props.onUserLinkOpen) {
      return;
    }
    this.props.onUserLinkOpen(url);
  }

  log(summary, log, callback) {
    if (summary === undefined) {
      throw new Error('summary is not set: `context.log(summary, log, callback)`');
    }
    if (log === undefined) {
      throw new Error('log is not set: `context.log(summary, log, callback)`');
    }
    if (!this.props.onLog) {
      return;
    }
    this.props.onLog(summary, log, callback);
  }

  resetTimers() {
    this.timerIds.forEach((id) => {
      clearTimeout(id);
    });
    this.timerIds = [];
  }

  reload(changed) {
    this.resetTimers();
    this.setState({
      started: false,
      finishing: false,
      finished: false,
      changed: changed || false,
    }, () => {
      const { onReload } = this.props;
      if (onReload) {
        onReload();
      }
      if (!this.props.onAppMenuShow) {
        return;
      }
      this.props.onAppMenuShow();
    });
  }

  isLoading() {
    if (!Object.values(this.state.lastPreloadFiles || {}).every((file) => file.loaded)) {
      return true;
    }
    return false;
  }

  renderAgreement() {
    const { agreement } = this.props;
    if (!agreement) {
      return null;
    }
    if (agreement.hideOnStartPage) {
      return null;
    }
    if (this.hasAgreement()) {
      return <Container maxWidth='md'>
        <Alert severity='success'>
          <AlertTitle>
            <FormattedMessage id='agreementAgreedHeader' values={{title: agreement.title}}/>
          </AlertTitle>
          <FormattedMessage id='agreementAgreedDescription'/>
          <Button
            variant="outlined"
            color='secondary'
            onClick={() => this.openAgreement()}
          >
            <FormattedMessage id="toAgreement"/>
          </Button>
        </Alert>
      </Container>;
    }
    return <Container maxWidth='md'>
      <Alert severity='info'>
        <AlertTitle>
          <FormattedMessage id='agreementNeedAgreeHeader' values={{title: agreement.title}}/>
        </AlertTitle>
        <FormattedMessage id='agreementNeedAgreeDescription'/>
        {!agreement.required && <Button
          variant="outlined"
          color='secondary'
          onClick={() => this.openAgreement()}
        >
          <FormattedMessage id="toAgreement"/>
        </Button>}
      </Alert>
    </Container>;
  }

  renderCreator() {
    const { intl, classes, param } = this.props;
    const userIdForDistributorParam = (param || [])
      .filter((param) => param.name === 'GOEMON_GENERATE_ID_FOR_DISTRIBUTOR')[0];
    const userIdForDistributor = (userIdForDistributorParam && userIdForDistributorParam.value === 'true') || false;
    return (
      <Container className={classes.author}>
        {(!this.props.creatorLogType || this.props.creatorLogType === 'pseudonymized') && <>
            <Typography variant="body1" component="div" className={classes.creatorLogTypeNotice}>
              <FormattedMessage id='noticePseudonymizedLogType' />
            </Typography>
            {userIdForDistributor && <Typography variant="body1" component="div" className={classes.creatorLogTypeNotice}>
              <FormattedMessage id='noticeGenerateIdForDistributor' />
            </Typography>}
          </>}
        {this.props.creatorUserInfo && <Author
            headerMessageId='creatorHeader'
            userInfo={this.props.creatorUserInfo}
            lang={intl.formatMessage({ id: 'lang' })}
          >
          </Author>}
        {this.props.creatorLogType === 'none' &&
            <Typography variant="body1" component="span" className={classes.creatorLogTypeInfo}>
              <FormattedMessage id='noticeNoneLogType' />
            </Typography>}
        <Container className={classes.authorPanel}>
          {this.props.authorUserInfo && <Author
              headerMessageId='authorHeader'
              userInfo={this.props.authorUserInfo}
              lang={intl.formatMessage({ id: 'lang' })}
            >
            </Author>}
          {this.props.authorLogType === 'pseudonymized' &&
            <Typography variant="body1" component="div" className={classes.creatorLogTypeNotice}>
              <FormattedMessage id='noticeAuthorPseudonymizedLogType' />
            </Typography>}
          {this.props.authorLogType === 'none' &&
              <Typography variant="body1" component="span" className={classes.creatorLogTypeInfo}>
                <FormattedMessage id='noticeAuthorNoneLogType' />
              </Typography>}
        </Container>
      </Container>
    )
  }

  renderTitle() {
    const { classes, children } = this.props;
    const needAgreement = this.needAgreement();
    const loading = this.isLoading();
    const autoStart = this.isAutoStart();
    const markdownRenderers = {
      img: ({alt, src, title,}) => (
        <img
            alt={alt}
            src={src}
            title={title}
            style={{
              maxWidth: Math.min(600, this.state.windowSize.innerWidth)
            }}
        />
      ),
    };
    return <Container disableGutters>
      <Typography variant="h1" component="h1" className={classes.title}>
        {this.props.title}
      </Typography>
      {this.props.description && !autoStart &&
        <Container maxWidth="sm" className={classes.description}>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={markdownRenderers}
            children={this.props.description}
          />
        </Container>}
      {!autoStart && this.renderCreator()}
      {!autoStart && children}
      {!children && <>
        {!autoStart && this.renderAgreement()}
        {!autoStart && needAgreement && <Button
          variant="contained"
          color="secondary"
          onClick={() => this.openAgreement()}
          className={classes.startButton}
        >
          <FormattedMessage id="toAgreement"/>
        </Button>}
        {!needAgreement && <Button
          variant="contained"
          color="secondary"
          onClick={() => this.start()}
          disabled={loading}
          className={classes.startButton}
        >
          {loading && <ClipLoader />}
          {!loading && <>
              <PlayArrowIcon />
              <FormattedMessage id={autoStart ? 'startingTask' : 'startTask'}/>
            </>}
        </Button>}
      </>}
    </Container>
  }

  renderMain() {
    return <div id="main" ref={this.mainRef}></div>;
  }

  renderResult() {
    const { classes, noGuideForPersonary } = this.props;
    if (this.state.waitForLink) {
      return <Loading />;
    }
    return <Container disableGutters>
      {this.state.result && <>
        <Typography variant="h1" component="h1" className={classes.title}>
          <FormattedMessage id='taskFinished'/>
        </Typography>
        <Container disableGutters maxWidth="sm" className={classes.description}>
          <p>
            <FormattedMessage id='taskFinishedDetail1'/>
          </p>
          <div className={classes.result}>
            <strong>{(this.state.result && this.state.result.summary)
              || <FormattedMessage id='taskFinishedNoSummary'/>}</strong>
          </div>
          {!noGuideForPersonary && <p>
            <FormattedMessage id='taskFinishedDetailPersonary'/>
          </p>}
        </Container>
      </>}
      {!this.state.result && <>
          <Typography variant="h1" component="h1" className={classes.title}>
            <FormattedMessage id='taskAborted'/>
          </Typography>
          <Container maxWidth="sm" className={classes.description}>
            <p>
              <FormattedMessage id='taskAbortedDetail'/>
            </p>
          </Container>
        </>}
      {this.state.nextLink && <Container maxWidth="sm">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.openUserLink(this.state.nextLink.url)}
          >
            {this.state.nextLink.text || <FormattedMessage id='nextLinkDefaultText' />}
          </Button>
        </Container>}
    </Container>
  }

  render() {
    const { classes } = this.props;
    return (
      <Container disableGutters className={classes.root}>
        {!this.state.started && this.renderTitle()}
        {this.state.started && !this.state.finished && this.renderMain()}
        {this.state.finished && this.renderResult()}
        {this.state.started && this.props.editable &&
          <Tooltip title={<FormattedMessage id='restartTask'/>} aria-label="restart">
            <Fab
              color="secondary"
              className={classes.reloadButton}
              onClick={() => this.reload()}
            >
              <ReplayIcon />
            </Fab>
          </Tooltip>}
        <BeforeUnloadComponent
          blockRoute={this.state.finishing}
        />
        <LoadingDialog processing={this.state.finishing ? 'sendingResult' : null} />
      </Container>
    )
  }
}

export default withStyles(styles)(injectIntl(Task))
