import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import firebase from 'firebase/app';
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';
import axios from "axios";

import Task from "./Task";
import { loadTask, mergeElements, getSystemParamSchema } from './TaskPanel';
import Loading from "./Loading";
import { recordAnonymousLog } from "./datastore/Log";
import { LogLevel } from "./Debugger";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
  },
  deleted: {
    marginTop: theme.spacing(10),
    padding: '1em',
  },
  cannotAccessAsAnonymous: {
    marginTop: theme.spacing(10),
    padding: '1em',
  },
});

class AnonymousTaskPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: undefined,
      forceLogin: null,
    };
  }

  componentDidMount() {
    this.ensureTaskLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureTaskLoaded();
    if (prevState.forceLogin === this.state.forceLogin) {
      return;
    }
    if (this.state.forceLogin) {
      this.signIn(this.state.forceLogin);
    }
  }

  signIn(providerName) {
    if (providerName !== 'google') {
      throw new Error(`Unknown auth provider: ${providerName}`);
    }
    const auth = this.props.auth || firebase.auth;
    const provider = new auth.GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  showError(message, error) {
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  getForceLogin(task, baseTask) {
    if (this.props.disableForceLogin) {
      return null;
    }
    if (!task) {
      return null;
    }
    const param = mergeElements(task.data.param, baseTask ? baseTask.data.param : null, (elem) => elem.name);
    const forceLoginParam = param.filter((elem) => elem.name === 'GOEMON_FORCE_LOGIN');
    if (forceLoginParam.length === 0) {
      return null;
    }
    const { value } = forceLoginParam[0];
    if (!value) {
      return null;
    }
    const nvalue = value.trim();
    if (nvalue.length === 0) {
      return null;
    }
    return nvalue;
  }

  reportNotImplemented() {
    this.showError(
      'notImplementedInAnonymousMode',
      new Error('Not implemented in anonymous mode'),
    );
  }

  openUserLink(url) {
    let relativeUrl = null;
    const prefixes = ['', 'https://goemon.cloud', window.location.origin];
    if (url && prefixes.some((prefix) => url.startsWith(prefix + '/'))) {
      const prefix = prefixes.filter((prefix) => url.startsWith(prefix + '/'))[0];
      relativeUrl = url.substring(prefix.length);
    }
    if (relativeUrl && relativeUrl.match(/^\/t\/.+/)) {
      const { history } = this.props;
      history.push(relativeUrl);
      return;
    }
    window.location.href = url;
  }

  taskStart() {
    const { intl } = this.props;
    console.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugStart' })
    );
    this.setState({
      taskStarted: Date.now(),
    });
  }

  taskFinish(summary, log, callback) {
    const taskId = this.state.task.id;
    const task = this.state.task;
    const now = Date.now();
    const meta = {
      task: { id: taskId, title: task.data.title },
      started: this.state.taskStarted,
      recorded: now,
      finished: now,
      duration: now - this.state.taskStarted,
    };

    const { intl } = this.props;
    console.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugFinish' }),
      [summary, log]);
    this.performRecordLog(
      `${task.data.title}: ${summary}`,
      { meta, data: log },
      'finish',
    )
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      }).catch((error) => {
        this.showError('errorFinishTask', error)
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  taskLog(summary, log, callback) {
    const taskId = this.state.task.id;
    const task = this.state.task;
    const now = Date.now();
    const meta = {
      task: { id: taskId, title: task.data.title },
      started: this.state.taskStarted,
      recorded: now,
      finished: null,
      duration: null,
    };
    const { intl } = this.props;
    console.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugLog' }),
      [summary, log]);
    this.performRecordLog(
      `${task.data.title}: ${summary}`,
      { meta, data: log },
      'log',
    )
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      }).catch((error) => {
        this.showError('errorLogTask', error)
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  async performRecordLog(summary, log, type) {
    const { firestore } = this.props;
    const taskId = this.state.task.id;
    await recordAnonymousLog(firestore, taskId, summary, log, type);
    let resp = await axios.put(`/api/v1/anonymous-logs`);
    let count = 3;
    while (resp.status === 200 && (resp.data || { updated: 0 }).updated === 0 && count > 0) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Retrying...', count);
      resp = await axios.put(`/api/v1/anonymous-logs`);
      count --;
    }
  }

  getSystemParamSchema() {
    if (this.systemParamSchema) {
      return this.systemParamSchema;
    }
    if (!this.props.intl) {
      return [];
    }
    this.systemParamSchema = getSystemParamSchema(this.props.intl);
    return this.systemParamSchema;
  }
  ensureTaskLoaded() {
    if (this.state.task !== undefined) {
      return;
    }
    if (!this.props.match || !this.props.firestore) {
      return;
    }
    setTimeout(() => {
      this.setState({
        task: null,
      }, () => {
        const { firestore } = this.props;
        loadTask(null, firestore, this.props.match.params.taskId)
          .then((loaded) => {
            this.setState({
              task: loaded.task,
              taskContext: loaded.taskContext,
              userTask: loaded.userTask,
              originalUserTask: loaded.userTask,
              taskFiles: loaded.taskFiles,
              creatorUserInfo: loaded.creatorUserInfo,
              forceLogin: this.getForceLogin(loaded.task, loaded.baseTask),
            });
            if (!this.props.onTaskLoad) {
              return;
            }
            this.props.onTaskLoad({
              importable: false,
              allowAnonymous: (loaded.task || {}).data.allowAnonymous || false,
            });
          }).catch((error) => {
            this.showError('errorLoadTask', error)
          });
      })
    }, 10);
  }

  render() {
    const { classes, children } = this.props;
    const { performAsAnonymous } = this.props;
    const { task, forceLogin } = this.state;
    if (task && task.data.trash) {
      return <Paper className={classes.deleted}>
        <FormattedMessage id='alreadyDeleted' />
      </Paper>;
    }
    if (forceLogin) {
      return <Loading/>;
    }
    if (task && performAsAnonymous && task.data.allowAnonymous !== true) {
      return <Paper className={classes.cannotAccessAsAnonymous}>
        <FormattedMessage id='cannotAccessAsAnonymous' />
      </Paper>;
    }
    const { baseTask, baseTaskFiles } = this.state;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    return (
      <div className={classes.root}>
        {!task && <Loading />}
        {task &&
          <Task
            context={this.state.taskContext}
            title={task.data.title}
            script={task.data.script}
            description={task.data.description}
            param={mergeElements(task.data.param, baseParam, (elem) => elem.name)}
            paramSchema={baseTask ? baseTask.data.paramschema : task.data.paramschema}
            systemParamSchema={this.getSystemParamSchema()}
            files={mergeElements(this.state.taskFiles, baseTaskFiles, (elem) => elem.data.name)}
            editable={false}
            creatorLogType={this.state.creatorLogType === undefined ? task.data.creatorLogType : this.state.creatorLogType}
            creatorUserInfo={(this.state.showCreator === undefined ? task.data.creatorId : this.state.showCreator) ? this.state.creatorUserInfo : null}
            onStart={() => this.taskStart()}
            onFinish={(summary, log, callback) => this.taskFinish(summary, log, callback)}
            onLog={(summary, log, callback) => this.taskLog(summary, log, callback)}
            onError={(message, error) => this.showError(message, error)}
            onDebug={(level, message, ...args) => console.debug(level, message, args)}
            onUserLinkOpen={(url) => this.openUserLink(url)}
            onAgreementOpen={() => this.reportNotImplemented()}
            onAppMenuHide={this.props.onAppMenuHide}
            onAppMenuShow={this.props.onAppMenuShow}
            onReload={() => this.reportNotImplemented()}
            onLTILoginURLRequest={(custom, resolve, reject) => this.reportNotImplemented()}
            onLTIGradeUpdateHandlerAdd={(callback) => this.reportNotImplemented()}
            onMessagingRequest={(options, callback) => this.reportNotImplemented()}
            onMessagingMessageSend={(message, callback) => this.reportNotImplemented()}
            onMessagingSetSchedule={(options, callback) => this.reportNotImplemented()}
            onMessagingClearSchedule={(callback) => this.reportNotImplemented()}
            onMessagingLastResultGet={(callback) => this.reportNotImplemented()}
            onHealthRequest={(options, callback) => this.reportNotImplemented()}
            onHealthHandlerSet={(options, callback) => this.reportNotImplemented()}
            onHealthHandlerClear={(callback) => this.reportNotImplemented()}
            onHealthLastResultGet={(callback) => this.reportNotImplemented()}
            onHealthLastDataGet={(query, callback) => this.reportNotImplemented()}
            onUserStorageSave={(storage, callback) => this.reportNotImplemented()}
          >
            {performAsAnonymous ? null : children}
          </Task>}
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(injectIntl(AnonymousTaskPanel)))
