import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { parseCronExpression } from 'cron-schedule';

import TaskEditor from "./TaskEditor";
import Task from "./Task";
import LoadingDialog from "./LoadingDialog";
import { LogLevel, errorToObject, errorToMessage } from "./Debugger";
import Loading from "./Loading";
import AnonymousTaskPanel from "./AnonymousTaskPanel";
import UnauthorizedPanel from "./UnauthorizedPanel";
import MessagingRequestDialog from "./MessagingRequestDialog";
import HealthRequestDialog from "./HealthRequestDialog";
import HealthAccessDialog from "./HealthAccessDialog";
import { recordLog } from "./datastore/Log";
import { GarminHealthContext } from "./health/Garmin";
import { AwareHealthContext } from "./health/Aware";

const EXPIRE_USER_CONTEXT = 1000 * 60 * 60 * 24 * 7;
const EXPIRE_SHARED_COPY = 1000 * 60 * 60 * 24;
const MAX_TITLE_LENGTH = 32;
const DELAY_CRON = 1000 * 60 * 1;
const EXPIRE_MESSAGING_SCHEDULE = 1000 * 60 * 60 * 24 * 14;
const EXPIRE_HEALTH_HANDLER = 1000 * 60 * 60 * 24 * 14;

const LIMIT_LOGS_DURATION = 1000;
const LIMIT_LOGS_COUNT = 20;

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
  },
  deleted: {
    marginTop: theme.spacing(10),
    padding: '1em',
  },
});

async function loadFiles(firestore, task, userTask) {
  let taskRef = null;
  let basePath = null;
  if (userTask) {
    const { uid } = userTask;
    taskRef = firestore
      .collection('user').doc(userTask.uid)
      .collection('task').doc(userTask.id);
    basePath = `users/${uid}/tasks/${userTask.id}/`;
  } else {
    taskRef = firestore
      .collection('task').doc(task.id);
    basePath = `tasks/${task.id}/`;
  }
  const files = await taskRef.collection('file').get();
  return files.docs
    .filter((file) => !file.data().deleted && !file.data().noContent)
    .map((file) => ({
      id: file.id,
      basePath,
      data: file.data(),
    }));
}

function predictImportType(mimeType) {
  if (!mimeType) {
    return null;
  }
  if (mimeType.match(/^(application|text)\/(x-)?(java|ecma)script$/)) {
    return 'javascript';
  }
  if (mimeType === 'text/css') {
    return 'css';
  }
  if (mimeType.match(/^image\/(.*)$/)) {
    return 'image';
  }
  return null;
}

async function loadUserInfo(firestore, creatorId) {
  if (!creatorId) {
    return null;
  }
  const ref = await firestore.collection('userinfo').doc(creatorId).get();
  if (!ref.exists) {
    return null;
  }
  return ref.data();
}

function validateTaskContext(taskContextRef, taskData, baseTaskData) {
  if (!taskContextRef.exists) {
    return false;
  }
  if (taskContextRef.data().updated < Date.now() - EXPIRE_USER_CONTEXT) {
    return false;
  }
  const { param } = taskData || {};
  const baseParam = baseTaskData ? baseTaskData.param : null;
  const groupIdsParam = [...(param || [])].concat(baseParam || [])
    .filter((param) => param.name === 'GOEMON_GROUP_IDS')[0];
  if (!groupIdsParam) {
    return true;
  }
  const groupIds = groupIdsParam.value
    .split(',')
    .map((value) => value.trim())
    .filter((value) => value);
  if (groupIds.length === 0) {
    return true;
  }
  const { groupId } = taskContextRef.data();
  return groupIds.includes(groupId);
}

async function loadTaskContext(user, firestore, taskId, taskData, baseTaskData) {
  const taskContextRef = firestore
    .collection('user').doc(user.uid)
    .collection('context').doc(taskId);
  let taskContext = await taskContextRef.get();
  if (validateTaskContext(taskContext, taskData, baseTaskData)) {
    return taskContext.data();
  }
  await axios.post(`/api/v1/users/${user.uid}/contexts/${taskId}`);
  taskContext = await taskContextRef.get();
  if (!taskContext.exists) {
    throw new Error(`Sync context failed: ${taskId}`);
  }
  return taskContext.data();
}

async function loadUserAgreement(user, firestore, taskId) {
  const agreementRef = await firestore
    .collection('user').doc(user.uid)
    .collection('agreement').doc(taskId)
    .get();
  if (!agreementRef.exists) {
    return {
      exists: false,
      userId: user.uid,
      taskId,
      data: null,
    };
  }
  return {
    exists: true,
    userId: user.uid,
    taskId,
    data: agreementRef.data()
  };
}

async function loadBaseTask(user, firestore, task, userTask) {
  const base = (userTask || task).data.base;
  if (!base) {
    return {
      baseTask: null,
      baseTaskFiles: null,
      baseCreatorUserInfo: null,
    };
  }
  const baseTaskRef = await firestore.collection('task').doc(base).get();
  if (!baseTaskRef.exists) {
    throw new Error(`Base task not found: ${base}`);
  }
  if (!baseTaskRef.data().distributable) {
    throw new Error(`Base task is not distributable: ${base}`);
  }
  const baseTask = {
    id: baseTaskRef.id,
    data: baseTaskRef.data(),
  };
  const baseCreatorUserInfo = await loadUserInfo(firestore, baseTask.data.creatorId);
  if (!user) {
    return {
      baseTask,
      baseCreatorUserInfo,
      baseTaskFiles: null,
    }
  }
  const baseTaskFiles = await loadFiles(firestore, baseTask);
  if (!baseTask.data.creatorId) {
    throw new Error(`creatorId of base task not found: ${base}`);
  }
  return {
    baseTask,
    baseTaskFiles,
    baseCreatorUserInfo,
  };
}

export async function loadTask(user, firestore, taskId, shareId) {
  if (shareId) {
    if (taskId) {
      throw new Error(`Both the taskId and the shareId are specified`)
    }
    const sharedTaskRef = await firestore
      .collection('user').doc(user.uid)
      .collection('shared').doc(shareId).get();
    let shared = null;
    if (!sharedTaskRef.exists || !sharedTaskRef.data().copied || sharedTaskRef.data().copied + EXPIRE_SHARED_COPY < Date.now()) {
      const globalSharedTaskRef = await firestore
        .collection('shared').doc(shareId).get();
      if (!globalSharedTaskRef.exists) {
        throw new Error(`Shared task not found: ${shareId}`);
      }
      shared = globalSharedTaskRef.data();
      if (shared.userId !== user.uid) {
        await firestore
          .collection('user').doc(user.uid)
          .collection('shared').doc(shareId)
          .set(Object.assign({}, shared, { copied: Date.now() }));
      }
    } else {
      shared = sharedTaskRef.data();
    }
    const userTaskRef = await firestore
      .collection('user').doc(shared.userId)
      .collection('task').doc(shared.taskId)
      .get();
    if (!userTaskRef.exists) {
      throw new Error(`Shared task not found: ${shared.userId}, ${shared.taskId}`);
    }
    const userTask = {
      id: shared.taskId,
      uid: shared.userId,
      shared: shared.userId !== user.uid,
      data: userTaskRef.data(),
    };
    const taskRef = await firestore
      .collection('task').doc(userTaskRef.data().taskId)
      .get();
    if (!taskRef.exists) {
      throw new Error(`Task not found: ${userTaskRef.data().taskId}`);
    }
    const task = {
      id: taskRef.id,
      data: taskRef.data(),
    };
    const taskFiles = await loadFiles(firestore, task, userTask);
    const creatorUserInfo = await loadUserInfo(firestore, shared.userId);
    const { baseTask, baseTaskFiles, baseCreatorUserInfo } = await loadBaseTask(
      user, firestore, task, userTask
    );
    const taskContext = await loadTaskContext(
      user, firestore, userTask.data.taskId, userTask.data, (baseTask || {}).data,
    );
    const userAgreement = await loadUserAgreement(user, firestore, userTask.data.taskId);
    return {
      task,
      taskContext,
      userTask,
      taskFiles,
      baseTask,
      baseTaskFiles,
      baseCreatorUserInfo,
      baseFilePath: `users/${shared.userId}/tasks/${userTaskRef.id}/`,
      creatorUserInfo,
      userAgreement,
    };
  }
  const taskRef = await firestore.collection('task').doc(taskId).get();
  if (!taskRef.exists) {
    throw new Error(`Task not found: ${taskId}`);
  }
  const task = {
    id: taskRef.id,
    data: taskRef.data(),
  };
  if (!user) {
    const creatorUserInfo = await loadUserInfo(firestore, task.data.creatorId);
    const taskFiles = task.data.allowAnonymous ? (
      await loadFiles(firestore, task)
    ) : null;
    const { baseTask, baseTaskFiles, baseCreatorUserInfo } = await loadBaseTask(
      user, firestore, task
    );
    return {
      task,
      taskContext: null,
      userTask: null,
      taskFiles,
      baseTask,
      baseTaskFiles,
      baseCreatorUserInfo,
      baseFilePath: `tasks/${taskId}/`,
      creatorUserInfo,
    };
  }
  const userTaskRefs = await firestore
    .collection('user').doc(user.uid).collection('task')
    .where('taskId', '==', taskId).get();
  if (userTaskRefs.empty) {
    const taskFiles = await loadFiles(firestore, task, null);
    const creatorUserInfo = await loadUserInfo(firestore, task.data.creatorId);
    const { baseTask, baseTaskFiles, baseCreatorUserInfo } = await loadBaseTask(
      user, firestore, task
    );
    const taskContext = await loadTaskContext(
      user, firestore, taskId, task.data, (baseTask || {}).data,
    );
    const userAgreement = await loadUserAgreement(user, firestore, taskId);
    return {
      task,
      taskContext,
      userTask: null,
      taskFiles,
      baseTask,
      baseTaskFiles,
      baseCreatorUserInfo,
      baseFilePath: `tasks/${taskId}/`,
      creatorUserInfo,
      userAgreement,
    };
  }
  const userTaskRef = userTaskRefs.docs[0];
  const userTask = {
    id: userTaskRef.id,
    uid: user.uid,
    shared: false,
    data: userTaskRef.data(),
  };
  const taskFiles = await loadFiles(firestore, task, userTask);
  const creatorUserInfo = await loadUserInfo(firestore, user.uid);
  const { baseTask, baseTaskFiles, baseCreatorUserInfo } = await loadBaseTask(
    user, firestore, task, userTask
  );
  const taskContext = await loadTaskContext(
    user, firestore, taskId, userTask.data, (baseTask || {}).data,
  );
  const userAgreement = await loadUserAgreement(user, firestore, taskId);
  return {
    task,
    taskContext,
    userTask,
    taskFiles,
    baseTask,
    baseTaskFiles,
    baseCreatorUserInfo,
    baseFilePath: `users/${user.uid}/tasks/${userTaskRef.id}/`,
    creatorUserInfo,
    userAgreement,
  };
}

function parseFilename(filename) {
  let filebody = filename;
  let fileext = '';
  const extPos = filename.indexOf('.', 1);
  if (extPos >= 0) {
    filebody = filename.substring(0, extPos);
    fileext = filename.substring(extPos);
  }
  return { filebody, fileext };
}

function isKnownError(message) {
  if (!message) {
    return false;
  }
  if (message.match(/^.*worker-javascript\.js' failed to load\.\s*$/)) {
    return true;
  }
  return false;
}

export function getSystemParamSchema(intl) {
  return [
    {
      name: 'GOEMON_ANALYTICS_URL',
      type: 'url',
      help: intl.formatMessage({ id: 'paramAnalyticsURL' }),
    },
    {
      name: 'GOEMON_GENERATE_ID_FOR_DISTRIBUTOR',
      type: 'boolean',
      help: intl.formatMessage({ id: 'paramGenerateIdForDistributor' }),
    },
    {
      name: 'GOEMON_AUTO_START',
      type: 'boolean',
      help: intl.formatMessage({ id: 'paramAutoStart' }),
    },
    {
      name: 'GOEMON_NO_GUIDE_FOR_PERSONARY',
      type: 'boolean',
      help: intl.formatMessage({ id: 'paramNoGuideForPersonary' }),
    },
    {
      name: 'GOEMON_GROUP_IDS',
      type: 'string',
      help: intl.formatMessage({ id: 'paramGroupIds' }),
    },
    {
      name: 'GOEMON_LTI_PROVIDER',
      type: 'string',
      format: 'json',
      help: intl.formatMessage({ id: 'paramLTIProvider' }),
    },
    {
      name: 'GOEMON_AGREEMENT',
      type: 'string',
      format: 'json',
      help: intl.formatMessage({ id: 'paramAgreement' }),
    },
    {
      name: 'GOEMON_FORCE_LOGIN',
      type: 'string',
      help: intl.formatMessage({ id: 'paramForceLogin' }),
    },
    {
      name: 'GOEMON_EMAIL_ALLOWLIST',
      type: 'string',
      help: intl.formatMessage({ id: 'paramEmailAllowlist' }),
    },
  ];
}

export function mergeElements(array1, array2, getNameFrom) {
  const r = !array1 ? [] : [...array1];
  if (!array2) {
    return r;
  }
  array2.forEach((elem) => {
    const name = getNameFrom(elem);
    const exists = r.some((relem) => getNameFrom(relem) === name);
    if (exists) {
      return;
    }
    r.push(elem);
  });
  return r;
}

class TaskPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: undefined,
      userTask: null,
      originalUserTask: null,
      baseTask: null,
      lastEvalError: null,
      taskFullscreen: false,
      debugLines: [],
      requestingTalkType: null,
      requestingHealthType: null,
      taskStarted: null,
    };
    this._globalError = (event) => {
      this.logGlobalError(event);
    }
    this.systemParamSchema = null;
    this.requestingTalkCallback = null;
    this.requestingHealthCallback = null;
    this.snapshotUnsubscribeHandlers = [];
    this.recentLogs = [];
  }

  componentDidMount() {
    this.ensureTaskLoaded();
    window.addEventListener('error',  this._globalError);
  }

  componentWillUnmount() {
    this.resetAllSubscribers();
    window.removeEventListener('error',  this._globalError);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.ensureTaskLoaded(prevProps)) {
      // タスクリロード時は全てのSubscriberをリセットする
      this.reload();
    }
  }

  showError(message, error) {
    const { intl } = this.props;
    if (message === 'errorEval') {
      this.debug(
        LogLevel.ERROR,
        intl.formatMessage({ id: 'debugError' }),
        [errorToMessage(error), errorToObject(error)]
      );
      this.setState({
        lastEvalError: error,
      });
    } else {
      this.debug(
        LogLevel.ERROR,
        intl.formatMessage({ id: 'debugError' }),
        [message, errorToMessage(error), errorToObject(error)]
      );
    }
    if (!this.props.onError) {
      console.error(message);
      console.error(error);
      return;
    }
    this.props.onError(message, error);
  }

  logGlobalError(event) {
    const { intl } = this.props;
    const { message, filename, lineno, colno, error } = event;
    if (isKnownError(message)) {
      return;
    }
    this.debug(
      LogLevel.WARN,
      intl.formatMessage({ id: 'debugGlobalError' }),
      [message, { message, filename, lineno, colno, error }]
    );
  }

  ensureTaskLoaded(prevProps) {
    if (this.state.task !== undefined) {
      const prevParams = (prevProps.match || {}).params || {};
      const currentParams = (this.props.match || {}).params || {};
      console.log('task params', prevParams, currentParams);
      if (prevParams.taskId && prevParams.taskId === currentParams.taskId) {
        return false;
      }
      if (prevParams.shareId && prevParams.shareId === currentParams.shareId) {
        return false;
      }
    }
    if (!this.props.match || !this.props.firestore || !this.props.user) {
      return false;
    }
    setTimeout(() => {
      this.setState({
        task: null,
      }, () => {
        const { user, firestore } = this.props;
        loadTask(
          user,
          firestore,
          this.props.match.params.taskId,
          this.props.match.params.shareId,
        )
          .then((loaded) => {
            this.setState({
              task: loaded.task,
              taskContext: loaded.taskContext,
              userTask: loaded.userTask,
              originalUserTask: loaded.userTask,
              taskFiles: loaded.taskFiles,
              baseFilePath: loaded.baseFilePath,
              creatorUserInfo: loaded.creatorUserInfo,
              baseTask: loaded.baseTask,
              baseTaskFiles: loaded.baseTaskFiles,
              baseCreatorUserInfo: loaded.baseCreatorUserInfo,
              userAgreement: loaded.userAgreement,
              requestingTalkType: null,
              requestingHealthType: null,
              taskStarted: null,
            }, () => {
              this.setTitle((loaded.userTask || loaded.task).data.title);
              if (!this.props.onTaskLoad) {
                return;
              }
              this.props.onTaskLoad({
                importable: loaded.userTask === null ? loaded.task.data.importable !== false : true,
                distributable: loaded.userTask === null ? loaded.task.data.distributable === true : loaded.userTask.data.distributable,
                noGuideForPersonary: this.hasNoGuideForPersonary(),
              });
            });
          }).catch((error) => {
            this.showError('errorLoadTask', error)
          });
      })
    }, 10);
    return true;
  }

  setTitle(title) {
    const { onTitleSet } = this.props;
    if (!onTitleSet) {
      return;
    }
    if (!title) {
      onTitleSet('Undefined');
      return;
    }
    if (title.length <= MAX_TITLE_LENGTH) {
      onTitleSet(title);
      return;
    }
    onTitleSet(title.substring(0, MAX_TITLE_LENGTH - 3) + '...');
  }

  userScriptChange(newValue) {
    const userTask = Object.assign({}, this.state.userTask);
    userTask.data = Object.assign({}, userTask.data);
    userTask.data.script = newValue;
    this.setState({ userTask, lastEvalError: null });
  }

  userTitleChange(newValue) {
    const userTask = Object.assign({}, this.state.userTask);
    userTask.data = Object.assign({}, userTask.data);
    userTask.data.title = newValue;
    this.setState({ userTask })
  }

  userDescriptionChange(newValue) {
    const userTask = Object.assign({}, this.state.userTask);
    userTask.data = Object.assign({}, userTask.data);
    userTask.data.description = newValue;
    this.setState({ userTask })
  }

  userParamChange(newValue) {
    const userTask = Object.assign({}, this.state.userTask);
    userTask.data = Object.assign({}, userTask.data);
    userTask.data.param = newValue.map((item) => Object.assign({}, item));
    this.setState({ userTask });
  }

  userParamSchemaChange(newValue) {
    const userTask = Object.assign({}, this.state.userTask);
    userTask.data = Object.assign({}, userTask.data);
    userTask.data.paramschema = newValue.map((item) => Object.assign({}, item));
    this.setState({ userTask });
  }

  userFilesUpload(files, callback) {
    this.performUploadUserFiles(files)
      .then((latestFiles) => {
        this.setState({
          taskFiles: latestFiles,
        }, () => {
          if (this.props.onPermissionChange) {
            this.props.onPermissionChange();
          }
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorUploadFiles', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performUploadUserFile(filename, file, samenameTrials) {
    const { firestore } = this.props;
    const { uid } = this.state.userTask;
    const fileRef = firestore
      .collection('user').doc(uid)
      .collection('task').doc(this.state.userTask.id)
      .collection('file');
    const samenameFiles = await fileRef.where('name', '==', filename).get();
    if (!samenameFiles.empty && samenameFiles.docs.filter((ref) => !ref.data().deleted && !ref.data().noContent).length > 0) {
      const { filebody, fileext } = parseFilename(file.name);
      const newFilename = `${filebody}_${(samenameTrials || 0) + 1}${fileext}`
      return await this.performUploadUserFile(
        newFilename, file, (samenameTrials || 0) + 1,
      );
    }
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    const importType = predictImportType(file.type);
    const added = await fileRef.add({
      created: Date.now(),
      updated: null,
      name: filename,
      hashSHA256: hashHex,
      size: file.size || -1,
      lastModified: file.lastModified || 0,
      type: file.type || null,
      importType,
      preload: importType !== null,
      priority: 100,
      deleted: false,
      noContent: true,
    });
    const formData = new FormData();
    formData.append('content', file);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    await axios.put(
      `/api/v1/users/${uid}/tasks/${this.state.userTask.id}/files/${added.id}`,
      formData,
      config,
    );
  }

  async performUploadUserFiles(files) {
    const filenames = [];
    files.forEach((file) => {
      if (!filenames.includes(file.name)) {
        filenames.push(file.name);
        return;
      }
      const { filebody, fileext } = parseFilename(file.name);
      for (let i = 0; i < 9999; i ++) {
        const filename = `${filebody}_${i + 1}${fileext}`
        if (!filenames.includes(filename)) {
          filenames.push(filename);
          return;
        }
      }
      throw new Error('Too many files');
    });
    await Promise.all(files
      .map((file, index) => this.performUploadUserFile(filenames[index], file))
    );
    const { firestore } = this.props;
    const { task, userTask } = this.state;
    return await loadFiles(firestore, task, userTask);
  }

  userFilesDelete(files, callback) {
    this.performDeleteUserFiles(files)
      .then((latestFiles) => {
        this.setState({
          taskFiles: latestFiles,
        }, () => {
          if (this.props.onPermissionChange) {
            this.props.onPermissionChange();
          }
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorDeleteFiles', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performDeleteUserFile(fileRef, fileId) {
    await fileRef.doc(fileId).update({
      deleted: true,
    });
    const { uid } = this.state.userTask;
    await axios.delete(
      `/api/v1/users/${uid}/tasks/${this.state.userTask.id}/files/${fileId}`,
    );
  }

  async performDeleteUserFiles(files) {
    const { firestore } = this.props;
    const fileRef = firestore
      .collection('user').doc(this.state.userTask.uid)
      .collection('task').doc(this.state.userTask.id)
      .collection('file');
    await Promise.all(files
      .map((file) => this.performDeleteUserFile(fileRef, file))
    );
    const { task, userTask } = this.state;
    return await loadFiles(firestore, task, userTask);
  }

  userFilesEdit(file, callback) {
    this.performEditUserFiles(file)
      .then((latestFiles) => {
        this.setState({
          taskFiles: latestFiles,
        }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorEditFiles', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performEditUserFiles(file) {
    const { firestore } = this.props;
    const fileRef = firestore
      .collection('user').doc(this.state.userTask.uid)
      .collection('task').doc(this.state.userTask.id)
      .collection('file');
    await fileRef.doc(file.id).update(file.data);
    const { task, userTask } = this.state;
    return await loadFiles(firestore, task, userTask);
  }

  userTaskSave(callback) {
    this.performSaveUserTask()
      .then((userTask) => {
        this.setState({
          userTask: userTask,
          originalUserTask: userTask,
        }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorSaveTask', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performSaveUserTask(readyToDeploy) {
    const { firestore } = this.props;
    const { uid, shared } = this.state.userTask;
    const userTaskDocRef = firestore
      .collection('user').doc(uid)
      .collection('task').doc(this.state.userTask.id);
    await userTaskDocRef.update({
      updated: Date.now(),
      readyToDeploy: readyToDeploy || 0,
      title: this.state.userTask.data.title || '',
      script: this.state.userTask.data.script || '',
      description: this.state.userTask.data.description || '',
      param: this.state.userTask.data.param || [],
      paramschema: this.state.userTask.data.paramschema || null,
    });
    const userTaskRef = await userTaskDocRef.get();
    return {
      uid,
      shared,
      id: userTaskRef.id,
      data: userTaskRef.data(),
    };
  }

  userTaskPublish(options, callback) {
    this.performPublishUserTask(options)
      .then((userTask) => {
        this.setState({
          userTask: userTask,
          originalUserTask: userTask,
        }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorPublishTask', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performPublishUserTask(options) {
    const { firestore } = this.props;
    await this.performSaveUserTask(Date.now());
    const { uid, shared } = this.state.userTask;
    const userTaskDocRef = firestore
      .collection('user').doc(uid)
      .collection('task').doc(this.state.userTask.id);
    if (options.distributable && !options.creatorId) {
      throw new Error('creatorId cannot be empty when distributable == true');
    }
    await userTaskDocRef.update({
      importable: options.importable || false,
      distributable: options.distributable || false,
      public: options.public || false,
      allowAnonymous: options.allowAnonymous || false,
      creatorLogType: options.creatorLogType || null,
      authorLogType: options.authorLogType || null,
      creatorId: options.creatorId || null,
    });
    await axios.put(`/api/v1/users/${uid}/tasks/${this.state.userTask.id}`);
    const userTaskRef = await userTaskDocRef.get();
    return {
      uid,
      shared,
      id: userTaskRef.id,
      data: userTaskRef.data(),
    };
  }

  updateShares(props, callback) {
    this.performUpdateShares(props)
      .then((userTask) => {
        this.setState({
          userTask,
        }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorUpdateShares', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performUpdateShares(props) {
    const { firestore } = this.props;
    const { shareId } = this.state.userTask.data;
    let newProps = Object.assign({}, props);
    const globalProps = {
      shares: props.shares,
      sharesWithLink: props.sharesWithLink,
      updated: Date.now(),
    };
    const { uid } = this.state.userTask;
    if (!shareId) {
      const added = await firestore.collection('shared').add(Object.assign({
        userId: uid,
        taskId: this.state.userTask.id,
        created: Date.now(),
      }, globalProps));
      newProps = Object.assign(newProps, {
        shareId: added.id,
      });
    }
    const userTaskDocRef = firestore
      .collection('user').doc(uid)
      .collection('task').doc(this.state.userTask.id);
    await userTaskDocRef.update(newProps);
    await axios.put(`/api/v1/users/${uid}/tasks/${this.state.userTask.id}/shares`);
    const userTaskRef = await userTaskDocRef.get();
    return {
      id: userTaskRef.id,
      uid,
      data: userTaskRef.data(),
    };
  }

  userTaskDelete(callback) {
    this.performDeleteUserTask()
      .then((userTask) => {
        this.setState({
          userTask: userTask,
          originalUserTask: userTask,
        }, () => {
          if (!callback) {
            return;
          }
          callback();
        });
      }).catch((error) => {
        this.showError('errorDeleteTask', error)
        if (!callback) {
          return;
        }
        callback();
      });
  }

  async performDeleteUserTask() {
    const { user, firestore } = this.props;
    await this.performSaveUserTask(Date.now());
    const { uid } = this.state.userTask;
    if (uid !== user.uid) {
      throw new Error('Cannot delete other\'s task');
    }
    const userTaskDocRef = firestore
      .collection('user').doc(uid)
      .collection('task').doc(this.state.userTask.id);
    await userTaskDocRef.update({
      trash: true,
    });
    await axios.put(`/api/v1/users/${uid}/tasks/${this.state.userTask.id}`);
    const userTaskRef = await userTaskDocRef.get();
    return {
      uid,
      id: userTaskRef.id,
      data: userTaskRef.data(),
    };
  }

  async performUpdateContext(contextData) {
    const { firestore, user } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const taskContextRef = firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId);
    await taskContextRef.update(contextData);
  }

  getTaskAgreement() {
    const { task, userTask, baseTask } = this.state;
    const taskObj = userTask || task;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    if (!taskObj) {
      return null;
    }
    const param = mergeElements(taskObj.data.param, baseParam, (elem) => elem.name);
    const agreementParam = param.filter((elem) => elem.name === 'GOEMON_AGREEMENT');
    if (agreementParam.length === 0) {
      return null;
    }
    const r = JSON.parse(agreementParam[0].value);
    if (!userTask) {
      return r;
    }
    return Object.assign(r, {
      required: false,
    });
  }

  hasNoGuideForPersonary() {
    const { task, userTask, baseTask } = this.state;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    const taskObj = userTask || task;
    if (!taskObj) {
      return false;
    }
    const param = mergeElements(taskObj.data.param, baseParam, (elem) => elem.name);
    const paramValue = param.filter((elem) => elem.name === 'GOEMON_NO_GUIDE_FOR_PERSONARY');
    if (paramValue.length === 0) {
      return false;
    }
    return paramValue[0].value === 'true';
  }

  wrapSnapshotUnsubscribeHandler(unsubscribe) {
    this.snapshotUnsubscribeHandlers.push(unsubscribe);
    return () => {
      unsubscribe();

      // Remove handlers
      const index = this.snapshotUnsubscribeHandlers.indexOf(unsubscribe);
      if (index < 0) {
        return;
      }
      this.snapshotUnsubscribeHandlers.splice(index, 1);
    };
  }

  resetAllSubscribers() {
    this.snapshotUnsubscribeHandlers.forEach((unsubscribe) => unsubscribe());
    this.snapshotUnsubscribeHandlers = [];
  }

  reload() {
    this.resetAllSubscribers();
  }

  taskStart() {
    const { intl } = this.props;
    this.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugStart' })
    );
    this.setState({
      taskStarted: Date.now(),
      lastEvalError: null,
    });
    this.performUpdateContext({
      started: Date.now(),
    })
      .then(() => console.log('Context updated'))
      .catch((error) => console.error('Context update error: ', error));
  }

  taskFinish(summary, log, callback) {
    this.resetAllSubscribers();
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const task = this.state.userTask || this.state.task;
    const now = Date.now();
    const meta = {
      task: { id: taskId, title: task.data.title },
      started: this.state.taskStarted,
      recorded: now,
      finished: now,
      duration: now - this.state.taskStarted,
    };

    const { intl } = this.props;
    this.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugFinish' }),
      [summary, log]);
    this.performRecordLog(
      `${task.data.title}: ${summary}`,
      { meta, data: log },
      'finish',
    )
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      }).catch((error) => {
        this.showError('errorFinishTask', error)
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  taskLog(summary, log, callback) {
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const task = this.state.userTask || this.state.task;
    const now = Date.now();
    const meta = {
      task: { id: taskId, title: task.data.title },
      started: this.state.taskStarted,
      recorded: now,
      finished: null,
      duration: null,
    };
    this.recentLogs = this.recentLogs.filter((log) => log.recorded > now - LIMIT_LOGS_DURATION);
    console.log('LIMIT', this.recentLogs);
    if (this.recentLogs.length >= LIMIT_LOGS_COUNT) {
      throw new Error(`Log limit exceeded ${LIMIT_LOGS_COUNT} / ${LIMIT_LOGS_DURATION}msec`);
    }
    this.recentLogs.push(meta);

    const { intl } = this.props;
    this.debug(
      LogLevel.INFO, intl.formatMessage({ id: 'debugLog' }),
      [summary, log]);
    this.performRecordLog(
      `${task.data.title}: ${summary}`,
      { meta, data: log },
      'log',
    )
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      }).catch((error) => {
        this.showError('errorLogTask', error)
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  async performRecordLog(summary, log, type) {
    const { user, firestore, storage } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    await recordLog(firestore, storage, user.uid, taskId, summary, log, type);
    let resp = await axios.put(`/api/v1/users/${user.uid}/logs`);
    let count = 3;
    while (resp.status === 200 && (resp.data || { updated: 0 }).updated === 0 && count > 0) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Retrying...', count);
      resp = await axios.put(`/api/v1/users/${user.uid}/logs`);
      count --;
    }
  }

  prepareLTILoginURL(custom, resolve, reject) {
    this.performPrepareLTILoginURL(custom)
      .then(resolve)
      .catch(reject);
  }

  async performPrepareLTILoginURL(custom) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const task = this.state.userTask || this.state.task;
    const { baseTask } = this.state;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    const param = mergeElements(task.data.param, baseParam, (elem) => elem.name);
    const ltiProviderParam = param.filter((elem) => elem.name === 'GOEMON_LTI_PROVIDER');
    if (ltiProviderParam.length === 0) {
      throw new Error('GOEMON_LTI_PROVIDER task parameter not defined');
    }
    await firestore
      .collection('user').doc(user.uid)
      .collection('lti').doc(taskId)
      .set({
        loginRequested: Date.now(),
        provider: JSON.parse(ltiProviderParam[0].value),
        custom: (custom ? JSON.parse(JSON.stringify(custom)) : null),
      });
    return `/lti/platform/users/${user.uid}/tasks/${taskId}/login`;
  }

  async extractScoreData(storage, score) {
    const { data, dataRef } = score;
    if (!dataRef) {
      // in-memory data
      return data;
    }
    // from storage
    const storageRef = storage.ref(dataRef);
    const url = await storageRef.getDownloadURL();
    const response = await axios.get(url);
    return response.data;
  }

  setLTIGradeUpdateHandler(callback) {
    const { user, firestore, storage } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    let unsubscribe = null;
    unsubscribe = this.wrapSnapshotUnsubscribeHandler(
      firestore
        .collection('user').doc(user.uid)
        .collection('lti').doc(taskId)
        .onSnapshot((doc) => {
          const data = doc.data();
          const { scores, lineItems } = data;
          if (!scores || !lineItems) {
            return;
          }
          if (scores.length === 0) {
            return;
          }
          const resolvedScoreTaskPairs = Object.keys(scores)
            .map((key) => ({
              key,
              value: this.extractScoreData(storage, scores[key]),
            }));
          Promise.all(resolvedScoreTaskPairs.map((pair) => pair.value))
            .then((resolvedScoreTaskValues) => {
              const kv = {};
              resolvedScoreTaskPairs.map((pair, index) => ({
                key: pair.key,
                value: resolvedScoreTaskValues[index],
              })).forEach((pair) => {
                kv[pair.key] = pair.value;
              });
              const finished = callback({
                scores: kv,
                lineItems,
              });
              if (finished) {
                unsubscribe();
              }
            })
            .catch((error) => {
              console.error('Cannot get scores', error);
              this.showError('errorGetScores', error);
            });
        }));
  }

  requestTalk(options, callback) {
    const { type } = options;
    this.requestingTalkCallback = callback;
    this.performRequestTalk(type)
      .then((result) => {
        const { waitForTalkgroupId, waitForQueryNotEmpty } = result;
        if (waitForTalkgroupId) {
          this.setState({
            requestingTalkType: type,
          }, () => {
            console.log('Waiting...');
            let unsubscribe = null;
            let called = 0;
            unsubscribe = this.wrapSnapshotUnsubscribeHandler(
              waitForTalkgroupId.onSnapshot((snapshot) => {
                if (!this.requestingTalkCallback) {
                  unsubscribe();
                  return;
                }
                console.log('Changed', snapshot);
                if (!snapshot.exists) {
                  return;
                }
                const { talkgroupId } = snapshot.data();
                if (!talkgroupId) {
                  return;
                }
                if (called) {
                  return;
                }
                called ++;
                unsubscribe();
                this.requestTalk(options, callback);
              }));
          });
          return;
        }
        if (waitForQueryNotEmpty) {
          this.setState({
            requestingTalkType: type,
          }, () => {
            console.log('Waiting...');
            let unsubscribe = null;
            let called = 0;
            unsubscribe = this.wrapSnapshotUnsubscribeHandler(
              waitForQueryNotEmpty.onSnapshot((snapshot) => {
                if (!this.requestingTalkCallback) {
                  unsubscribe();
                  return;
                }
                console.log('Changed', snapshot);
                if (snapshot.empty) {
                  return;
                }
                if (called) {
                  return;
                }
                called ++;
                unsubscribe();
                this.requestTalk(options, callback);
              }));
          });
          return;
        }
        if (!callback) {
          return;
        }
        this.setState({
          requestingTalkType: null,
        }, () => {
          const { talkgroupId, talkuserId } = result;
          callback(talkgroupId || talkuserId, null);
          this.requestingTalkCallback = null;
        });
      })
      .catch((error) => {
        console.error('Cannot request talkgroup', error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  cancelTalkRequest() { 
    if (!this.requestingTalkCallback) {
      return;
    }
    const callback = this.requestingTalkCallback;
    this.requestingTalkCallback = null;
    this.setState({
      requestingTalkType: null,
    }, () => {
      callback(null, null);
    });
  }

  sendMessage(message, callback) {
    this.performSendMessage(message)
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      })
      .catch((error) => {
        console.error('Cannot send message', message, error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  setSchedule(options, callback) {
    this.performSetSchedule(options)
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      })
      .catch((error) => {
        console.error('Cannot set schedule', options, error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  clearSchedule(callback) {
    this.performClearSchedule()
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      })
      .catch((error) => {
        console.error('Cannot clear schedule', error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  getLastResult(callback) {
    this.performGetLastResult()
      .then((result) => {
        if (!callback) {
          return;
        }
        callback(result, null);
      })
      .catch((error) => {
        console.error('Cannot get last result', error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  async performRequestTalk(type) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const typeMatch = type.match(/^(.+)group$/);
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const contextDoc = firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId);
    if (typeMatch) {
      const groupType = typeMatch[1];
      const { talkgroupId } = contextRef.data();
      if (talkgroupId) {
        return {
          talkgroupId,
          waitForTalkgroupId: null,
        };
      }
      await contextDoc
        .update({
          talkgroupRequest: groupType,
          talkuserRequest: null,
          talkuserId: null,
          talkgroupId: null,
          updated: Date.now(),
        });
      return {
        talkgroupId: null,
        waitForTalkgroupId: contextDoc,
      };
    }
    const talkuserQuery = firestore
      .collection('user')
      .doc(user.uid)
      .collection('talkuser')
      .where('connected', '==', true)
      .where('type', '==', type);
    const talkuserRefs = await talkuserQuery
      .get();
    if (talkuserRefs.empty) {
      await contextDoc
        .update({
          talkgroupRequest: null,
          talkuserRequest: type,
          talkuserId: null,
          talkgroupId: null,
          updated: Date.now(),
        });
      return {
        talkuserId: null,
        waitForQueryNotEmpty: talkuserQuery,
      };
    }
    const talkuserId = talkuserRefs.docs[0].id;
    await contextDoc
      .update({
        talkuserId,
        talkgroupRequest: null,
        talkuserRequest: null,
        talkgroupId: null,
        updated: Date.now(),
      });
    return {
      talkuserId,
      waitForQueryNotEmpty: null,
    };
  }

  async performSendMessage(message) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const { talkgroupId, talkuserId } = contextRef.data();
    if (!talkgroupId && !talkuserId) {
      throw new Error(`Talkgroup or Talkuser not found: ${taskId}`);
    }
    if (talkgroupId) {
      const created = Date.now();
      const added = await firestore
        .collection('user').doc(user.uid)
        .collection('talkgroup').doc(talkgroupId)
        .collection('message').add({
          created,
          updated: created,
          sent: false,
          message: JSON.stringify(message),
        });
      await axios.post(`/api/v1/users/${user.uid}/talkgroups/${talkgroupId}/messages/${added.id}`);
      return;
    }
    const created = Date.now();
    const added = await firestore
      .collection('user').doc(user.uid)
      .collection('talkuser').doc(talkuserId)
      .collection('message').add({
        created,
        updated: created,
        sent: false,
        message: JSON.stringify(message),
      });
    await axios.post(`/api/v1/users/${user.uid}/talkusers/${talkuserId}/messages/${added.id}`);
  }

  async performSetSchedule(options) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const currentTime = Date.now();
    if (!options.schedule) {
      throw new Error('schedule not specified');
    }
    if (!options.url && !options.script && !options.message) {
      throw new Error('One of message, url, or script must be defined.');
    }
    const next = options.schedule ? parseCronExpression(options.schedule)
      .getNextDate(new Date(currentTime + DELAY_CRON)).getTime() : null;
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const { talkgroupId, talkuserId } = contextRef.data();
    const talkContext = {};
    if (talkgroupId) {
      talkContext.talkgroupId = talkgroupId;
    } else if (talkuserId) {
      talkContext.talkuserId = talkuserId;
    } else {
      throw new Error('Talkuser and Talkgroup is not defined');
    }
    const timezoneOffset = options.timezoneOffset || (new Date().getTimezoneOffset());
    await firestore
      .collection('user').doc(user.uid)
      .collection('talkagent').doc(taskId)
      .set(Object.assign(talkContext, JSON.parse(JSON.stringify(options)), {
        next,
        expiration: Date.now() + EXPIRE_MESSAGING_SCHEDULE,
        timezoneOffset,
        lastsent: currentTime,
      }));
  }

  async performClearSchedule() {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const scheduleRef = await firestore
      .collection('user').doc(user.uid)
      .collection('talkagent').doc(taskId)
      .get();
    if (!scheduleRef.exists) {
      return;
    }
    await firestore
      .collection('user').doc(user.uid)
      .collection('talkagent').doc(taskId)
      .update({
        updated: Date.now(),
        next: null,
      });
  }

  async performGetLastResult() {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const scheduleRef = await firestore
      .collection('user').doc(user.uid)
      .collection('talkagent').doc(taskId)
      .get();
    if (!scheduleRef.exists) {
      return null;
    }
    const { lastsent, error, console } = scheduleRef.data();
    return {
      lastsent,
      error: error || null,
      console: console ? JSON.parse(console) : null,
    };
  }

  requestHealth(options, callback) {
    const { type } = options;
    this.requestingHealthCallback = callback;
    this.performRequestHealth(type)
      .then((result) => {
        const {
          waitForQueryNotEmpty, waitForUpdate, checkForUpdate, showAccess,
        } = result;
        if (waitForQueryNotEmpty) {
          this.setState({
            requestingHealthType: type,
          }, () => {
            console.log('Waiting...');
            let unsubscribe = null;
            let called = 0;
            unsubscribe = this.wrapSnapshotUnsubscribeHandler(
              waitForQueryNotEmpty.onSnapshot((snapshot) => {
                if (!this.requestingHealthCallback) {
                  unsubscribe();
                  return;
                }
                console.log('Changed', snapshot);
                if (snapshot.empty) {
                  return;
                }
                if (called) {
                  return;
                }
                called ++;
                unsubscribe();
                this.requestHealth(options, callback);
              }));
          });
          return;
        }
        if (waitForUpdate) {
          this.setState({
            requestingHealthType: type,
          }, () => {
            console.log('Waiting...');
            let unsubscribe = null;
            let called = 0;
            unsubscribe = this.wrapSnapshotUnsubscribeHandler(
              waitForUpdate.onSnapshot((snapshot) => {
                if (!this.requestingHealthCallback) {
                  unsubscribe();
                  return;
                }
                console.log('Changed', snapshot);
                if (checkForUpdate && !checkForUpdate(snapshot)) {
                  return;
                }
                if (called) {
                  return;
                }
                called ++;
                unsubscribe();
                this.requestHealth(options, callback);
              }));
          });
          return;
        }
        this.setState({
          showHealthAccess: showAccess,
        });
        if (!callback) {
          return;
        }
        this.setState({
          requestingHealthType: null,
        }, () => {
          const { healthuserId } = result;
          callback(healthuserId, null);
          this.requestingHealthCallback = null;
        });
      })
      .catch((error) => {
        console.error('Cannot request health', error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  setHealthHandler(options, callback) {
    this.performSetHealthHandler(options)
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      })
      .catch((error) => {
        console.error('Cannot set health handler', options, error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  clearHealthHandler(callback) {
    this.performClearHealthHandler()
      .then(() => {
        if (!callback) {
          return;
        }
        callback(null);
      })
      .catch((error) => {
        console.error('Cannot clear health handler', error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  cancelHealthRequest() {
    if (!this.requestingHealthCallback) {
      return;
    }
    const callback = this.requestingHealthCallback;
    this.requestingHealthCallback = null;
    this.setState({
      requestingHealthType: null,
    }, () => {
      callback(null, null);
    });
  }

  getLastHealthData(query, callback) {
    this.performGetLastHealthData(query)
      .then((result) => {
        if (!callback) {
          return;
        }
        callback(result, null);
      })
      .catch((error) => {
        console.error('Cannot retrieve last data', query, error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  getLastHealthResult(callback) {
    this.performGetLastHealthResult()
      .then((result) => {
        if (!callback) {
          return;
        }
        callback(result, null);
      })
      .catch((error) => {
        console.error('Cannot get last result', error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  getHealthService(type, taskId) {
    const { user, firestore } = this.props;
    if (type === 'garmin') {
      return new GarminHealthContext(firestore, user);
    }
    if (type === 'aware') {
      return new AwareHealthContext(firestore, user, taskId);
    }
    throw new Error(`Unexpected type: ${type}`);
  }

  async performRequestHealth(type) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const healthService = this.getHealthService(type, taskId);
    const contextDoc = firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId);
    const {
      healthuserId, waitForQueryNotEmpty,
      waitForUpdate, checkForUpdate, showAccess,
    } = await healthService.getHealthuserId();
    if (!healthuserId) {
      const userDoc = firestore
        .collection('user')
        .doc(user.uid);
      const userRef = await userDoc.get();
      const healthRequest = {
        healthUpdated: Date.now(),
      };
      if (userRef.exists) {
        await userDoc.update(healthRequest);
      } else {
        await userDoc.set(healthRequest);
      }
      await contextDoc
        .update({
          healthRequest: type,
          healthuserId: null,
          updated: Date.now(),
        });
      return {
        healthService,
        healthuserId,
        waitForQueryNotEmpty,
        waitForUpdate,
        checkForUpdate,
        showAccess: null,
      };
    }
    await contextDoc
      .update({
        healthuserId,
        healthRequest: null,
        updated: Date.now(),
      });
    return {
      healthService,
      healthuserId,
      waitForQueryNotEmpty: null,
      waitForUpdate: null,
      checkForUpdate: null,
      showAccess,
    };
  }

  async performSetHealthHandler(options) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const { healthuserId } = contextRef.data();
    const healthContext = {
      healthuserId,
    };
    if (!healthuserId) {
      throw new Error(`Health request not found: ${taskId}`);
    }
    const healthService = this.getHealthServiceByHealthuserId(healthuserId, taskId);
    if (healthService === null) {
      throw new Error(`Unknown health service: ${healthuserId}`);
    }
    if (!options.url && !options.script && !options.message) {
      throw new Error('One of message, url, or script must be defined.');
    }
    const modifiedOptions = healthService.validateOptions(JSON.parse(JSON.stringify(options)));
    const timezoneOffset = options.timezoneOffset || (new Date().getTimezoneOffset());
    await firestore
      .collection('user').doc(user.uid)
      .collection('health').doc(taskId)
      .set(Object.assign(healthContext, modifiedOptions, {
        expiration: Date.now() + EXPIRE_HEALTH_HANDLER,
        timezoneOffset,
        updated: Date.now(),
      }));
  }

  async performClearHealthHandler() {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const scheduleRef = await firestore
      .collection('user').doc(user.uid)
      .collection('health').doc(taskId)
      .get();
    if (!scheduleRef.exists) {
      return;
    }
    await firestore
      .collection('user').doc(user.uid)
      .collection('health').doc(taskId)
      .update({
        updated: Date.now(),
        expiration: null,
      });
  }

  getHealthServiceByHealthuserId(healthuserId, taskId) {
    const m = healthuserId.match(/^([^:]+):.+/);
    if (!m) {
      throw new Error(`Unexpected type: ${healthuserId}`);
    }
    const type = m[1];
    return this.getHealthService(type, taskId);
  }

  async performGetLastHealthData(query) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const contextRef = await firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId)
      .get();
    if (!contextRef.exists) {
      throw new Error(`Context not found: ${taskId}`);
    }
    const { healthuserId } = contextRef.data();
    if (!healthuserId) {
      throw new Error(`Health request not found: ${taskId}`);
    }
    const healthService = this.getHealthServiceByHealthuserId(healthuserId, taskId);
    if (healthService === null) {
      throw new Error(`Unknown health service: ${healthuserId}`);
    }
    return await this.getLastData(healthuserId, query);
  }

  async performGetLastHealthResult() {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const healthRef = await firestore
      .collection('user').doc(user.uid)
      .collection('health').doc(taskId)
      .get();
    if (!healthRef.exists) {
      return null;
    }
    const { lastsent, error, console } = healthRef.data();
    return {
      lastsent,
      error: error || null,
      console: console ? JSON.parse(console) : null,
    };
  }

  saveUserStorage(storage, callback) {
    this.performSaveUserStorage(storage)
      .then((taskContext) => {
        this.setState({
          taskContext,
        }, () => {
          if (!callback) {
            return;
          }
          callback(null);
        });
      })
      .catch((error) => {
        console.error('Cannot store user storage', storage, error);
        if (!callback) {
          return;
        }
        callback(error);
      });
  }

  async performSaveUserStorage(storage) {
    const { user, firestore } = this.props;
    const taskId = this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id;
    const taskContextDoc = firestore
      .collection('user').doc(user.uid)
      .collection('context').doc(taskId);
    await taskContextDoc
      .update({
        updated: Date.now(),
        storage,
      });
    const taskContext = await taskContextDoc.get();
    return taskContext.data();
  }

  isDirty() {
    if (!this.state.userTask || !this.state.originalUserTask) {
      return false;
    }
    if (this.state.userTask.data.script !== this.state.originalUserTask.data.script) {
      return true;
    }
    if (this.state.userTask.data.title !== this.state.originalUserTask.data.title) {
      return true;
    }
    if (this.state.userTask.data.description !== this.state.originalUserTask.data.description) {
      return true;
    }
    if (JSON.stringify(this.state.userTask.data.param || []) !== JSON.stringify(this.state.originalUserTask.data.param || [])) {
      return true;
    }
    if (this.state.userTask.data.paramschema !== this.state.originalUserTask.data.paramschema) {
      return true;
    }
    return false;
  }

  inputTalkuserCode(code, callback) {
    this.performResolveTalkuserCode(code)
      .then((talkuser) => {
        if (!callback) {
          return;
        }
        callback(talkuser, null);
      })
      .catch((error) => {
        console.error('Cannot resolve talkuer code', error);
        if (!callback) {
          return;
        }
        callback(null, error);
      });
  }

  async performResolveTalkuserCode(code) {
    const { firestore, user } = this.props;
    const talkuserCodeDoc = firestore
      .collection('user').doc(user.uid)
      .collection('talkuserassociationcode').doc(code);
    await talkuserCodeDoc.set({
      updated: Date.now(),
    });
    const r = await axios.post(`/api/v1/users/${user.uid}/talkusercodes/${code}`);
    console.log('TalkuserCode', r.data);
    if ((r.data || {}).status === 'not_found') {
      return null;
    }
    const talkuserCodeRef = await talkuserCodeDoc.get();
    if (!talkuserCodeRef.exists) {
      throw new Error('Broken data');
    }
    const { talkuserId, type, token, connected } = talkuserCodeRef.data();
    if (!talkuserId || !type || !token) {
      throw new Error('Insufficient data');
    }
    const talkuserDoc = firestore
      .collection('user').doc(user.uid)
      .collection('talkuser').doc(talkuserId);
    let talkuserRef = await talkuserDoc.get();
    if (!talkuserRef.exists) {
      const created = Date.now();
      await talkuserDoc.set({
        created,
        updated: created,
        type,
        token: token,
        connected,
      });
    } else {
      await talkuserDoc.update({
        updated: Date.now(),
        type,
        token: token,
        connected,
      });
    }
    await axios.post(`/api/v1/users/${user.uid}/talkusers/${talkuserId}`);
    talkuserRef = await talkuserDoc.get();
    if (!talkuserRef.exists) {
      throw new Error(`Talkuser not exists: ${talkuserId}`)
    }
    if (connected) {
      await this.sendWelcomeMessage(talkuserId, type);
    }
    return {
      id: talkuserRef.id,
      data: talkuserRef.data(),
    };
  }

  async sendWelcomeMessage(talkuserId, type) {
    if (type !== 'line') {
      // unsupported
      return;
    }
    const { firestore, user, intl } = this.props;
    const lastContextRefs = await firestore
      .collection('user')
      .doc(user.uid)
      .collection('context')
      .where('talkuserRequest', '==', type)
      .orderBy('updated', 'desc')
      .limit(1)
      .get();
    let messageText = null;
    if (!lastContextRefs.empty && lastContextRefs.docs[0].data().talkuserId === null) {
      messageText = intl.formatMessage(
        { id: 'welcomeTalkuserMessageWithTask' },
        {
          taskId: lastContextRefs.docs[0].id,
          type,
        },
      );
    } else {
      messageText = intl.formatMessage(
        { id: 'welcomeTalkuserMessage' },
        {
          type,
        });
    }
    const message = {
      type: 'text',
      text: messageText,
    };
    const created = Date.now();
    const added = await firestore
      .collection('user').doc(user.uid)
      .collection('talkuser').doc(talkuserId)
      .collection('message').add({
        created,
        updated: created,
        sent: false,
        message: JSON.stringify(message),
      });
    await axios.post(`/api/v1/users/${user.uid}/talkusers/${talkuserId}/messages/${added.id}`);
  }

  downloadQRCode() {
    window.open(`/qrcode${window.location.pathname}`);
  }

  creatorPolicyChange(policy) {
    this.setState({
      showCreator: policy.showCreator,
      creatorLogType: policy.creatorLogType,
    });
  }

  authorPolicyChange(policy) {
    this.setState({
      authorLogType: policy.authorLogType,
    });
  }

  debug(level, message, args) {
    if (!this.state.userTask) {
      return;
    }
    setTimeout(() => {
      const oldLines = this.state.debugLines.map((v) => v);
      oldLines.push({
        time: Date.now(),
        level,
        message,
        args,
      });
      this.setState({
        debugLines: oldLines,
      });
    }, 0);
  }

  getSystemParamSchema() {
    if (this.systemParamSchema) {
      return this.systemParamSchema;
    }
    if (!this.props.intl) {
      return [];
    }
    this.systemParamSchema = getSystemParamSchema(this.props.intl);
    return this.systemParamSchema;
  }

  openUserLink(url) {
    const {intl} = this.props;
    if (!this.state.userTask) {
      let relativeUrl = null;
      const prefixes = ['', 'https://goemon.cloud', window.location.origin];
      if (url && prefixes.some((prefix) => url.startsWith(prefix + '/'))) {
        const prefix = prefixes.filter((prefix) => url.startsWith(prefix + '/'))[0];
        relativeUrl = url.substring(prefix.length);
      }
      if (relativeUrl && relativeUrl.match(/^\/t\/.+/)) {
        const { history } = this.props;
        history.push(relativeUrl);
        return;
      }
      window.location.href = url;
      return;
    }
    alert(intl.formatMessage({
      id: 'preventUserLink',
    }, {
      url,
    }));
  }

  openAgreement() {
    const { task, userTask } = this.state;
    const url = `/agreement/${task.id}`;
    if (userTask) {
      window.open(url, '_blank');
      return;
    }
    const { history } = this.props;
    history.push(url);
  }

  canAccess() {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    const allowlistParam = this.getEmailAllowlist();
    if (!allowlistParam) {
      return true;
    }
    const allowlist = allowlistParam
      .split(';').map((d) => d.trim()).filter((d) => d.length > 0);
    return allowlist.some((domain) => user.email.endsWith(domain));
  }

  getEmailAllowlist() {
    const { task, userTask, baseTask } = this.state;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    if (!task) {
      // ロード前
      return null;
    }
    if (userTask) {
      return null;
    }
    const param = mergeElements(task.data.param, baseParam, (elem) => elem.name);
    const allowlistParam = param.filter((elem) => elem.name === 'GOEMON_EMAIL_ALLOWLIST');
    if (allowlistParam.length === 0) {
      return null;
    }
    return allowlistParam[0].value;
  }

  getUserEmailDomain() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    const { email } = user;
    const index = email.indexOf('@');
    if (index < 0) {
      return null;
    }
    return email.substring(index + 1);
  }

  render() {
    const { classes } = this.props;
    if (!this.canAccess()) {
      return <AnonymousTaskPanel
        firestore={this.props.firestore}
        disableForceLogin={true}
        onSignIn={this.props.onReSignIn}
        onError={(message, error) => this.showError(message, error)}
      >
        <UnauthorizedPanel
          currentUserEmail={(this.props.user || {}).email}
          emailAllowlist={this.getEmailAllowlist()}
          onReSignIn={this.props.onReSignIn}
        />
      </AnonymousTaskPanel>;
    }
    const task = this.state.userTask || this.state.task;
    if (task && task.data.trash) {
      return <Paper className={classes.deleted}>
        <FormattedMessage id='alreadyDeleted' />
      </Paper>;
    }
    const { baseTask, baseTaskFiles } = this.state;
    const baseParam = baseTask ? (baseTask.data.param || []) : [];
    const agreement = this.getTaskAgreement();
    const taskId = task ? (this.state.userTask ? this.state.userTask.data.taskId : this.state.task.id) : null;
    return (
      <div className={classes.root}>
        {!task && <Loading />}
        {task && (!this.state.taskFullscreen) && <>
          <Task
            context={this.state.taskContext}
            title={task.data.title}
            script={baseTask ? baseTask.data.script : task.data.script}
            description={task.data.description}
            param={mergeElements(task.data.param, baseParam, (elem) => elem.name)}
            paramSchema={baseTask ? baseTask.data.paramschema : task.data.paramschema}
            systemParamSchema={this.getSystemParamSchema()}
            files={mergeElements(this.state.taskFiles, baseTaskFiles, (elem) => elem.data.name)}
            editable={this.state.userTask}
            allowAutoStart={this.state.taskContext && this.state.taskContext.started && !this.state.userTask}
            creatorLogType={this.state.creatorLogType === undefined ? task.data.creatorLogType : this.state.creatorLogType}
            authorLogType={baseTask ? baseTask.data.authorLogType : null}
            creatorUserInfo={(this.state.showCreator === undefined ? task.data.creatorId : this.state.showCreator) ? this.state.creatorUserInfo : null}
            authorUserInfo={this.state.baseCreatorUserInfo}
            search={this.props.search}
            agreement={agreement}
            taskURL={`${window.location.origin}/t/${taskId}`}
            userAgreement={this.state.userAgreement}
            userEmailDomain={this.getUserEmailDomain()}
            noGuideForPersonary={this.hasNoGuideForPersonary()}
            onStart={() => this.taskStart()}
            onFinish={(summary, log, callback) => this.taskFinish(summary, log, callback)}
            onLog={(summary, log, callback) => this.taskLog(summary, log, callback)}
            onError={(message, error) => this.showError(message, error)}
            onDebug={(level, message, ...args) => this.debug(level, message, args)}
            onUserLinkOpen={(url) => this.openUserLink(url)}
            onAgreementOpen={() => this.openAgreement()}
            onAppMenuHide={this.props.onAppMenuHide}
            onAppMenuShow={this.props.onAppMenuShow}
            onReload={() => this.reload()}
            onLTILoginURLRequest={(custom, resolve, reject) => this.prepareLTILoginURL(custom, resolve, reject)}
            onLTIGradeUpdateHandlerAdd={(callback) => this.setLTIGradeUpdateHandler(callback)}
            onMessagingRequest={(options, callback) => this.requestTalk(options, callback)}
            onMessagingMessageSend={(message, callback) => this.sendMessage(message, callback)}
            onMessagingSetSchedule={(options, callback) => this.setSchedule(options, callback)}
            onMessagingClearSchedule={(callback) => this.clearSchedule(callback)}
            onMessagingLastResultGet={(callback) => this.getLastResult(callback)}
            onHealthRequest={(options, callback) => this.requestHealth(options, callback)}
            onHealthHandlerSet={(options, callback) => this.setHealthHandler(options, callback)}
            onHealthHandlerClear={(callback) => this.clearHealthHandler(callback)}
            onHealthLastResultGet={(callback) => this.getLastHealthResult(callback)}
            onHealthLastDataGet={(query, callback) => this.getLastHealthData(query, callback)}
            onUserStorageSave={(storage, callback) => this.saveUserStorage(storage, callback)}
          />
          <MessagingRequestDialog
            requesting={this.state.requestingTalkType}
            onCancel={() => this.cancelTalkRequest()}
            onInputCode={(code, callback) => this.inputTalkuserCode(code, callback)}
            onError={(message, error) => this.showError(message, error)}
          />
          <HealthRequestDialog
            user={this.props.user}
            requesting={this.state.requestingHealthType}
            onCancel={() => this.cancelHealthRequest()}
          />
          <HealthAccessDialog
            user={this.props.user}
            showAccess={this.state.showHealthAccess}
            onClose={() => this.setState({ showHealthAccess: null })}
          />
        </>}
        {this.state.userTask &&
          <BeforeUnloadComponent
            blockRoute={this.isDirty()}
          >
            <TaskEditor
              user={this.props.user}
              permission={this.props.permission}
              userTask={this.state.userTask}
              baseTask={this.state.baseTask}
              lastEvalError={this.state.lastEvalError}
              files={this.state.taskFiles}
              baseFilePath={this.state.baseFilePath}
              debugLines={this.state.debugLines || []}
              creatorUserInfo={this.state.creatorUserInfo}
              systemParamSchema={this.getSystemParamSchema()}
              onScriptChange={(newValue) => this.userScriptChange(newValue)}
              onTitleChange={(newValue) => this.userTitleChange(newValue)}
              onDescriptionChange={(newValue) => this.userDescriptionChange(newValue)}
              onParamChange={(newValue) => this.userParamChange(newValue)}
              onParamSchemaChange={(newValue) => this.userParamSchemaChange(newValue)}
              onFilesUpload={(files, callback) => this.userFilesUpload(files, callback)}
              onFilesEdit={(file, callback) => this.userFilesEdit(file, callback)}
              onFilesDelete={(files, callback) => this.userFilesDelete(files, callback)}
              onSave={(callback) => this.userTaskSave(callback)}
              onPublish={(options, callback) => this.userTaskPublish(options, callback)}
              onTaskDelete={(callback) => this.userTaskDelete(callback)}
              onFullscreen={(taskFullscreen) => this.setState({ taskFullscreen })}
              onDownloadQRCode={() => this.downloadQRCode()}
              onCreatorPolicyChanged={(policy) => this.creatorPolicyChange(policy)}
              onAuthorPolicyChanged={(policy) => this.authorPolicyChange(policy)}
              onSharesUpdate={(props, callback) => this.updateShares(props, callback)}
            />
          </BeforeUnloadComponent>}
        <LoadingDialog processing={this.props.processing} />
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(injectIntl(TaskPanel)))
