async function recordLargeLog(
  currentTime, firestore, storage, userId, taskId, summary, log, type,
) {
  const finished = currentTime.getTime();
  const newTaskRef = await firestore
    .collection('user').doc(userId)
    .collection('log').add({
      finished,
      taskId,
      summary,
      type,
    });
  const logId = newTaskRef.id;
  const logPath = `user/${userId}/log/${logId}.json`;
  const logStorageRef = storage.ref(logPath);
  await logStorageRef.putString(
    JSON.stringify(log),
    'raw',
    {
      contentType: 'application/json',
    },
  );
  await newTaskRef.update({
    dataRef: logPath,
    reserved: false,
  });
}

export async function recordLog(
  firestore, storage, userId, taskId, summary, log, type,
) {
  const finished = Date.now();
  try {
    await firestore
      .collection('user').doc(userId)
      .collection('log').add({
        finished,
        taskId,
        summary,
        data: JSON.stringify(log),
        reserved: false,
        type,
      });
  } catch(e) {
    if (e.code !== 'invalid-argument') {
      throw e;
    }
    // Fallback for large document (>1MB)
    console.log('Maybe large document size, fallback to storage', {
      reason: e,
    });
    await recordLargeLog(
      {
        getTime: () => finished,
      },
      firestore, storage, userId, taskId, summary, log, type,
    );
  }
}

export async function recordAnonymousLog(
  firestore, taskId, summary, log, type,
) {
  const finished = Date.now();
  try {
    await firestore
      .collection('anonymouslog').add({
        finished,
        taskId,
        summary,
        data: JSON.stringify(log),
        reserved: false,
        type,
      });
  } catch(e) {
    if (e.code !== 'invalid-argument') {
      throw e;
    }
    // Fallback for large document (>1MB)
    console.error('Maybe large document size, but cannot fallback to storage in anonymous mode', {
      reason: e,
    });
    throw e;
  }
}
