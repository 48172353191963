import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import firebase from 'firebase/app';
import queryString from 'query-string';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactMarkdown from 'react-markdown';
import GoogleButton from 'react-google-button';
import ClipLoader from "react-spinners/ClipLoader";

const styles = {
  header: {
    padding: '2em',
    backgroundColor: '#fff',
    marginTop: '1em',
    textAlign: 'left',
  },
  titleContainer: {
    padding: 0,
    display: 'flex',
  },
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
    marginRight: 'auto',
  },
  avatar: {
    marginLeft: 'auto',
    width: '1em',
    height: '1em',
    marginRight: 8,
  },
  description: {
    marginBottom: '2em',
  },
  login: {
    margin: 'auto',
  },
  notice: {
    paddingTop: '1em',
  },
}


class AnonymousPanel extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.search);
    this.state = {
      customToken: query['_ct'],
    };
  }

  signIn() {
    const auth = this.props.auth || firebase.auth;
    const provider = new auth.GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  render() {
    const { classes, intl } = this.props;
    const { allowAnonymous, onLoginAsAnonymous } = this.props;
    const { customToken } = this.state;
    return (
      <Container disableGutters maxWidth='sm'>
        <Paper elevation={0} className={classes.header}>
          <Container className={classes.titleContainer}>
            <Avatar
              alt='GO-E-MON'
              src='/images/icon.png'
              variant='square'
              className={classes.avatar}
            />
            <Typography variant="h1" component="h1" className={classes.title}>
              <FormattedMessage id={'needToLogin'} />
            </Typography>
          </Container>
          <p className={classes.description}>
            <ReactMarkdown>
              {intl.formatMessage({ id: 'loginTaskDescription' })}
            </ReactMarkdown>
          </p>
          {customToken && <ClipLoader />}
          {!customToken && <GoogleButton
            className={classes.login}
            onClick={() => this.signIn()}
          />}
          {allowAnonymous && onLoginAsAnonymous && <div>
            <p>
              <FormattedMessage id='anonymousLoginPrefix' />
            </p>
            <Button color='secondary' variant='contained' onClick={onLoginAsAnonymous}>
              <FormattedMessage id='anonymousLogin' />
            </Button>
          </div>}
          <div className={classes.notice}>
            <FormattedMessage id='loginNotice' />
            <a href='https://scrapbox.io/cogtask-me/Terms_of_Use' target='_blank' rel='noreferrer'>
              <FormattedMessage id='agreement' />
            </a>
          </div>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(AnonymousPanel))
