export const messages = {
  lang: 'ja',
  about: 'GO-E-MONについて',
  loading: '読み込み中...',
  needToLogin: '参加にはログインが必要です',
  anonymousLoginPrefix: 'または、',
  anonymousLogin: 'ログインせずに参加する',
  cannotAccessAsAnonymous: 'このタスクはログインしていないユーザによる実施が許可されていません。',
  notImplementedInAnonymousMode: 'この機能は匿名モードでは利用できません',
  loginTaskDescription: `GO-E-MONはオンライン実験プラットフォームです。以下をお読みの上、ログインしてご参加ください。

#### 実験データは配信者が管理します
GO-E-MONから配信されるタスクを実行すると、配信者(必要に応じて実験作成者)が管理するストレージに実施結果データが保存されます。
その後、このデータはGO-E-MONからは削除されます。

#### あなたもこのデータを管理することができます
あなたの個人ストレージをGO-E-MONに設定することで、実施結果データはあなたも取得し、活用することができます。

詳しくは [GO-E-MONとは](https://scrapbox.io/cogtask-me/GO-E-MON%E3%81%A8%E3%81%AF_-_%E5%8F%82%E5%8A%A0%E8%80%85%E5%90%91%E3%81%91) を参照してください。`,
  needToLoginAsAnotherAccount: 'このアカウントによる使用は許可されていません',
  unauthorizedTaskDescription: `現在ログインしているアカウント {currentUserEmail} による使用は許可されていません。

このタスクを使用するためには、**{emailAllowlist} のいずれかのアカウント**を使用してください。

以下のボタンを押すとログアウトしますので、正しいアカウントでログインし直してください。`,
  loginDescription: '利用開始するにはサインインしてください。',
  loginNotice: 'サインインすると利用規約に同意したものとみなします。',
  agreement: '利用規約',
  greetingHeader0: '簡単にタスクを配信',
  greetingMessage0: 'Webブラウザだけで、JavaScriptを書いてタスクを配信できます。',
  greetingHeader1: '安全にログを保存',
  greetingMessage1: 'タスクによって記録されたログは個人のPDSに保存されます。ログは自身で管理し、必要に応じて活用することができます。',
  notLoggedIn: 'ログインしていません',
  currentUser: 'ユーザ設定',
  openDashboard: 'ダッシュボード',
  openInquiry: 'お問い合わせ',
  openUserManual: 'ユーザマニュアル',
  openTermsOfUse: '利用規約',
  signout: 'ログアウト',
  next: '次へ',
  previous: '前へ',
  register: '登録完了',
  submit: '送信',
  newTask: '新規タスクの追加',
  newTitlePrefix: 'コピー - ',
  distributeTitlePrefix: '配信用 - ',
  processingNewTask: '新規タスクを作成しています...',
  processingCopiedTask: 'タスクをコピーしています...',
  processingDistributedTask: '配信タスクを作成しています...',
  viewTab: 'タスクをやってみる',
  editTab: 'タスクを作る',
  viewTasksDescription: 'GO-E-MONでは色々なタスクを行うことができます。タスクをやってみよう！',
  editTasksDescription: 'GO-E-MONでは自分でタスクを作って配信することができます！',
  myTasks: '作成したタスク',
  publicTasks: '公開されているタスク',
  noPublicTasks: '公開されているタスクはありません。',
  recentTasks: '最近参照したタスク',
  noRecentTasks: '最近参照したタスクはありません。',
  sharedTasks: '共有されたタスク',
  noSharedTasks: '共有されたタスクはありません。',
  reloadTasks: 'もっと見る',
  noMyTasks: '作成したタスクはありません',
  openTask: '開く',
  moreTasks: 'もっと見る',
  lessTasks: '閉じる',
  tasksOrderCount: 'アクセス数',
  tasksOrderUsers: '実施者数',
  tasksOrderUpdated: '最終更新',
  editTask: '編集',
  taskSave: '保存',
  taskShare: '共有',
  taskClose: '閉じる',
  taskOpen: '開く',
  fileDelete: '削除',
  taskFullscreen: '全画面',
  taskFullscreenExit: '全画面解除',
  startTask: 'はじめる',
  startingTask: '開始しています...',
  restartTask: '再読み込み',
  toAgreement: '同意管理画面へ',
  alreadyDeleted: 'タスクはすでに削除されています。',
  sendingResult: '結果を送信しています...',
  scriptTab: 'コード',
  usageTab: '使用方法',
  deployTab: '配備',
  descriptionTab: '説明文',
  paramTab: '設定',
  fileTab: 'ファイル',
  debugTab: 'デバッグ',
  taskFinished: 'ありがとうございました！',
  taskFinishedDetail1: 'タスクの実施ありがとうございました。以下の結果はタスク作成者に送信されます。(このタスクで入力した内容以外に、あなたのアカウントの名前やメールアドレスが送信されることはありません。)',
  taskFinishedDetailPersonary: 'また、自身のアカウントに[Personary同期設定]を行うと、自身のPersonaryにも結果を保存することができます。',
  taskFinishedNoSummary: '(実施結果)',
  taskAborted: 'ありがとうございました！',
  taskAbortedDetail: 'タスクは中断されました。またお試しください！',
  nextLinkDefaultText: 'OK',
  preventUserLink: '編集中のタスクからURLリンクが発火されました。ユーザが実行した場合は以下のURLへ遷移します: {url}',
  descriptionScriptTab: 'タスクの動作をJavaScriptによって定義することができます。',
  descriptionDescriptionTab: 'タスク開始時のメッセージを定義することができます。Markdownによる修飾が利用可能です。',
  descriptionParamTab: 'タスクの動作をパラメータによって定義することができます。',
  descriptionFileTab: 'タスクに添付するファイルを設定できます。(合計10MBまで, 制限緩和についてはヘルプを参照)',
  descriptionDeployTab: 'タスクの配備に関する設定ができます。',
  descriptionDebugTab: '`console.log(line)` でデバッグ出力ができます。',
  addParam: '追加',
  deleteParam: '削除',
  editSchema: '設定スキーマの編集',
  paramEditTitle: '設定の編集',
  paramEditSchemaTitle: '設定スキーマの編集',
  paramEditOK: 'OK',
  paramEditCancel: 'キャンセル',
  columnParamname: '設定名',
  columnParamvalue: '設定値',
  paramName: '設定名',
  paramValue: '設定値',
  paramAnalyticsURL: 'タスクで得られたデータの分析方法の解説リソースのURL (任意。ログに格納されます)',
  paramGenerateIdForDistributor: '配信者内でのみ識別可能なユーザIDを生成する場合はtrue (デフォルトはタスク内でのみ識別可能なユーザIDを生成)',
  paramAutoStart: '一度開始したことがある場合に自動的に開始する場合はtrue',
  paramNoGuideForPersonary: 'Personaryに関する紹介を自動的に表示したくない場合はtrue',
  paramGroupIds: '参加者を複数の群に分割する場合は、群の識別子をカンマで区切って指定',
  paramEmailAllowlist: '利用可能なメールアドレスを制限する場合は、許可するドメイン(@...)をセミコロンで区切って指定',
  paramLTIProvider: 'LTIプロバイダー情報をJSON文字列により指定',
  paramAgreement: '同意画面を利用する場合、JSON文字列により指定',
  paramForceLogin: '未ログイン時にこのタスクを開いた際に強制的にログインページに遷移したい場合は、ログイン方式名を設定します。',
  paramInvalidNameError: '設定名はアルファベットから始まりアルファベットまたは数字、アンダーバーのみ指定可能です。',
  paramDuplicatedNameError: 'すでに同一の名前の項目が定義されています。',
  paramInvalidIntegerError: '整数値指定が不正です。',
  paramInvalidNumberError: '数値指定が不正です。',
  paramInvalidBooleanError: '真偽値にはtrueかfalseが指定可能です。',
  paramInvalidURLError: 'URLが不正です。',
  paramInvalidURLSchemeError: 'URLのプロトコルが不正です。httpまたはhttpsが指定可能です。',
  paramInvalidJSONError: 'JSON文字列が不正です: {error}',
  paramInvalidSchemaError: 'スキーマJSON文字列が不正です: {error}',
  paramSchema: '設定スキーマをJSON文字列で指定',
  openPersonary: 'Personary同期設定',
  copyTask: 'タスクを自身のアカウントにコピー',
  distributeTask: 'タスクを自身のアカウントから配信',
  plrStartIntroduction: 'テストの記録はPersonaryで管理できます',
  plrStartNeedPLR: 'GO-E-MONで行ったテストの記録はPersonaryで管理できます。タスクを実施した際の記録、あるいは自身が作成したタスクの実施記録は全てPersonaryに保存されます。',
  plrStartInstallPLR: 'Personaryアプリはいつでもインストール・設定できます。Personaryアプリを設定するまでは実施記録はGO-E-MONサーバに一時保管されます。',
  plrStep0InstallPLR: '手順1. Personaryをインストール・設定してください',
  plrStep0InstallPersonary: 'テストの記録をPersonaryで管理したい場合はPersonaryアプリをインストールしてください。',
  plrStep0SettingPersonary: 'Personaryは、クラウドストレージサービスを利用してデータの保管を行います。Googleドライブ {email}にデータを保管するよう設定してください。',
  plrStep0AboutPersonary: 'Personaryアプリはアセンブローグ株式会社が提供するデータ管理・運用アプリです。Personaryアプリの利用規約をご確認の上ご利用ください。',
  plrStep0CautionForOldVersion: '2021/6以前にPersonaryをStoreからインストールされた場合は、新バージョンへのアップグレードをお願いいたします。(詳細はクリック)',
  plrStep1RegisterFriend: '手順2. GO-E-MONをあなたのPersonaryにフレンド登録してください',
  plrStep1QRCode: 'あなたのPersonaryアプリを起動し、 フレンド登録 から、以下のQRコードを読み取るか、',
  plrStep1RequestString: 'または、次のフレンドリクエスト文字列をPersonaryに入力してください。',
  plrFriendRequestCopied: 'フレンドリクエスト文字列をコピーしました。',
  plrStep1PleaseRegister: 'フレンドへの登録が完了したら、登録完了ボタンを押してください。(Personaryへのデータ転送までにしばらく時間がかかる可能性があります。)',
  placeholderScript: 'JavaScript',
  placeholderDescription: 'Markdown',
  deployPublish: 'タスクの配備',
  descriptionPublish: 'タスクを他のユーザが利用するには、配備を行う必要があります。配備後に行った変更は再配備するまでユーザには反映されません。',
  hasUnpublishedChanges: '配備されていない変更があります',
  taskImportable: 'タスクのコピーを許可する',
  taskPublic: 'タスクを公開する (URLを知らないユーザでもこのタスクを見つけることができます。)',
  taskAllowAnonymous: 'ログインしていないユーザによる実施を許可する',
  taskDistributable: '他の配信者がこのタスクを配信可能にする',
  taskShowCreator: '配信者情報を表示する',
  taskCreatorLogType: '配信者へのログ送信方法',
  taskPseudonymizedLogType: '配信者に対して仮名化してログを送信する',
  taskNoneLogType: '配信者に対してログを送信しない',
  taskAuthorLogType: 'タスク作成者へのログ送信方法',
  taskAuthorPseudonymizedLogType: 'タスク作成者に対して仮名化してログを送信する',
  taskAuthorNoneLogType: 'タスク作成者に対してログを送信しない',
  noticePseudonymizedLogType: '実施ログは配信者に対して送信されます。このタスクで入力した内容以外に、あなたのアカウントの名前やメールアドレスが送信されることはありません。',
  noticeGenerateIdForDistributor: '実施ログには配信者内で識別可能なユーザIDが付与されます。このタスクは同じ配信者の別のタスクの実行記録と連携し動作する可能性があります。',
  noticeNoneLogType: '配信者に対していかなる情報も送信されません。',
  noticeAuthorPseudonymizedLogType: '実施ログは作成者に対して送信されます。このタスクで入力した内容以外に、あなたのアカウントの名前やメールアドレスが送信されることはありません。',
  noticeAuthorNoneLogType: '作成者に対していかなる情報も送信されません。',
  userInfoNotDefined: '有効な配信者情報が定義されていません - ',
  distributorInfoNotShown: 'タスクを配信するためには配信者情報を表示する必要があります。',
  authorInfoNotShown: '他の配信者に利用許可をするためには配信者情報を表示する必要があります。',
  authorDescriptionNotDefined: '他の配信者に利用許可をするためにはタスク説明を記入する必要があります。',
  taskPublish: '配備',
  headerTaskReuse: '他の配信者への利用許可',
  headerTaskCreator: '配信者情報の表示',
  deployLink: 'タスクへの参加',
  descriptionLink: 'タスクを他のユーザに実施してもらうには、以下のURLまたはQRコードを配布してください。',
  downloadQRCode: 'QRコードのダウンロード',
  urlCopied: 'クリップボードにコピーしました',
  manageTask: 'タスクの管理',
  descriptionDeleteTask: 'タスクを削除します。この操作は元に戻すことはできません。',
  deleteTask: 'タスクを削除',
  deleteConfirmTitle: 'タスクの削除',
  deleteConfirmContent: 'タスク {title} を削除します。この操作は元に戻せません。',
  deleteConfirmCancel: 'キャンセル',
  deleteConfirmOK: '削除',
  dropZoneArea: 'ここにファイルをドラッグ＆ドロップまたはクリックしてファイルアップロード',
  columnPreload: '読み込み',
  columnFilename: 'ファイル名',
  columnSize: 'サイズ',
  columnModified: '更新日時',
  columnImportType: '形式',
  columnPriority: '優先度',
  columnDownload: 'DL',
  importTypeJavaScript: 'JavaScript',
  importTypeCSS: 'CSS',
  importTypeImage: '画像',
  importTypeGeneric: 'その他',
  uploadNotPermittedTitle: 'アップロードできません',
  uploadNotPermittedContent: 'ファイルのアップロードはできません。アップロードは {capacity} まで許可されています。不要なファイルを削除してください。(現在 {current})',
  uploadNotPermittedOK: 'OK',
  inquirySending: '送信中...',
  inquirySent: '送信しました',
  inquiryDescription: '本アプリに関するお問い合わせは以下のフォームからお願いします。追って、メールにてご連絡差し上げます。',
  inquiryLabelUserID: 'ユーザID',
  inquiryHelperTextUserID: 'お問い合わせに関する状況の確認に使用します。',
  inquiryLabelMail: '連絡先メールアドレス',
  inquiryHelperTextMail: 'お問い合わせへの返信の目的にのみ利用します。',
  inquiryPlaceholderMail: '連絡先メールアドレスを入力してください。',
  inquiryLabelText: 'お問い合わせ内容',
  inquiryPlaceholderText: 'お問い合わせの内容を入力してください。',
  inquiryHelperTextText: '問題の発生状況など、質問内容を記入してください。氏名等の個人情報はできる限り記載しないようにしてください。',
  noDebugMessages: 'デバッグメッセージはありません。',
  debugStart: 'スクリプトを開始しました',
  debugFinish: 'スクリプトを終了しました',
  debugError: 'スクリプトでエラーが発生しました',
  debugGlobalError: 'ブラウザでエラーが発生しました',
  debugLog: 'ログを記録しました',
  debugPreloadStarting: '読み込んでいます...',
  debugPreloadFinished: '読み込み完了',
  debugPreloadFailed: '読み込み失敗',
  logLevelDebug: 'デバッグ',
  logLevelInfo: '情報',
  logLevelError: 'エラー',
  logLevelWarning: '警告',
  authorHeader: '作成者',
  creatorHeader: '配信者',
  shareTitle: 'ユーザと共有',
  shareType: '共有',
  sharesDisabled: '無効',
  sharesWithMembers: 'メンバーのみ編集可能',
  sharesWithLink: 'リンクを知っている誰もが編集可能',
  shareMembers: '共有するメンバー(メールアドレス)',
  shareURL: '共有URL',
  copyShareLink: 'リンクをコピー',
  shareClose: '閉じる',
  requestingShareId: '共有しています...',
  settingsUserHeader: '作成者の情報',
  settingsUserDescription: 'タスクには作成者の情報を表示することができます。',
  settingsUserNicknameEN: '作成者の表示名(英語)',
  settingsUserNicknameJA: '作成者の表示名(日本語, 任意)',
  settingsUserAffiliationEN: '作成者の所属(英語)',
  settingsUserAffiliationJA: '作成者の所属(日本語, 任意)',
  settingsUserContact: '連絡先情報',
  settingsUserNoContacts: '有効な連絡先を1つ以上指定する必要があります。',
  settingsUserNoNicknames: '表示名を入力する必要があります。',
  settingsUserNoAffiliations: '所属名を入力する必要があります。',
  settingsUserContactMail: 'メールアドレスを連絡先に使用する - ',
  settingsUserContactTwitter: 'Twitterアカウント',
  placeholderUserContactTwitter: '@あなたのTwitterアカウント',
  settingsUserContactFacebook: 'Facebook プロフィールのURL',
  placeholderUserContactFacebook: 'https://www.facebook.com/あなたのFacebookアカウント',
  settingsTokenHeader: '開発者トークン',
  settingsNewTokenLabel: 'トークン名',
  settingsNewTokenPlaceholder: '新規作成するトークン名',
  settingsNewTokenExpirationLabel: '有効期限',
  settingsNewTokenExpirationNever: '期限を設定しない',
  settingsNewTokenExpiration1Hour: '1時間',
  settingsNewTokenExpiration1Day: '1日間',
  settingsNewTokenExpiration1Week: '1週間',
  settingsNewTokenExpiration30Days: '30日間',
  newToken: '新規作成',
  columnTokenname: 'トークン名',
  columnTokenexpires: '有効期限',
  rowNoTokenExpiration: '期限なし',
  deleteToken: '無効化',
  tokenDeleteConfirmTitle: 'トークンの無効化',
  tokenDeleteConfirmMessage: 'トークン {tokenNames} を無効化します。よろしいですか？',
  tokenDeleteConfirmCancel: 'キャンセル',
  tokenDeleteConfirmOK: '無効化',
  cantReadToken: 'トークンを確認できるのは今回限りです。',
  tokenCopied: 'クリップボードにコピーしました。',
  copyToken: 'コピー',
  settingsOptionalHeader: 'その他の設定',
  authorDefaultNickname: '名前 未設定',
  authorDefaultAffiliation: '組織 未設定',
  settingsAuthorExample: '作成者表示例',
  langEN: '英語',
  langJA: '日本語',
  saveUserInfo: '保存',
  noSettings: '設定可能な項目はありません。',
  garminConnected: 'Garmin Connectと接続済みです。',
  garminNotConnected: 'Garmin Connectと接続されていません。',
  startGarminAuth: 'Garmin Connectとの連携を開始',
  disconnectGarminAuth: 'Garmin Connectとの連携を解除',
  agreementNoAgreements: 'このタスクに同意取得設定は定義されていません。',
  agreementDisagree: '同意を撤回する',
  agreementAgreedDescription: '実験参加への同意はいつでも撤回可能です。',
  agreementAgreedHeader: '実験 "{title}" への参加同意済み',
  agreementDisagreeConfirmTitle: '撤回確認',
  agreementDisagreeConfirmDescription: `
同意を撤回してよろしいですか？
同意を撤回された場合、データの分析は行いません。

同意はいつでも再度実施することができます。
`,
  agreementDisagreeConfirmCancel: 'キャンセル',
  agreementDisagreeProcessing: '同意を撤回しています...',
  agreementDisagreeConfirmed: '撤回',
  agreementNeedAgreeHeader: '実験 "{title}" への参加手続きが必要です',
  agreementNeedAgreeDescription: 'このコンテンツを利用するには実験説明の確認・同意書の記入が必要です。',
  agreementAgree: '同意する',
  agreementAgreeResultTitle: 'ありがとうございました！',
  agreementAgreeResultClose: '閉じる',
  agreementAgreeResultDescription: `
同意を受け付けました！
タスクを引き続き実施してください！
`,
  agreementConfirmProgress: '送信しています...',
  agreementRequirementConfirmed: '確認済みです。',
  agreementVideoDescription: '動画を確認したら、「確認した」ボタンを押してください。',
  agreementRequirementConfirm: '確認した',
  agreementPDFDescription: '説明を確認したら、「確認した」ボタンを押してください。',
  agreementSignDescription: 'これでよろしければ、提出ボタンを押してください。',
  agreementSignTouchDescription: '以下をお読みの上、タッチパネルで署名をしてください。',
  agreementSignTabTouch: 'タッチで署名',
  agreementSignTabPhoto: '署名してカメラ撮影',
  agreementSignWriteByTouchpanel: 'タッチパネルで入力',
  agreementSignTouchInputDescription: 'タッチパネルで署名してください。画面に書きやすい方向で書いてください。 ({subject})',
  agreementSignTouchInputCancel: 'キャンセル',
  agreementSignTouchInputComplete: '完了',
  agreementSignImage: '署名',
  agreementSignPhotoDescription: '[同意書]({src})をダウンロード、印刷の上、署名欄に{subjects}の署名を記入してください。完了したら、同意書を以下のボタンで撮影しアップロードしてください。',
  agreementSignUploadButton: 'クリックして写真をアップロード',
  agreementSignCancelButton: '削除',
  agreementReturn: 'タスクに戻る',
  agreementLogSummaryAgreed: '同意しました。',
  agreementLogSummaryAgreementRevoked: '同意を撤回しました。',
  talkgroupSettings: 'メッセージ機能を利用する',
  settingsTalkgroupDescription: 'グループ "{name}" を通じてGO-E-MONタスクとメッセージのやりとりをしましょう！',
  activateTalkgroup: 'メッセージ機能の有効化',
  selectTask: '選択',
  activatedTalkuserTitle: 'メッセージサービスを連携しました',
  activatedTalkuserDescription: 'メッセージサービス {type} のユーザー {name} を連携しました。このウィンドウを閉じてください。',
  activatedTalkuserConfirm: 'OK',
  activatedHealthuserTitle: 'センサーサービスを連携しました',
  activatedHealthuserDescription: 'センサーサービス {type} を連携しました。このウィンドウを閉じてください。',
  activatedHealthuserConfirm: 'OK',
  settingsTalkuserHeader: 'メッセージサービス連携',
  settingsNoTalkusers: '連携しているメッセージサービスはありません。',
  settingsDisconnectTalkuser: '連携解除',
  disconnectTalkuserConfirmTitle: 'メッセージサービス連携の解除',
  disconnectTalkuserConfirmDescription: 'メッセージサービス {type} のユーザー {name} の連携を解除します。よろしいですか？',
  disconnectTalkuserConfirmCancel: 'キャンセル',
  disconnectTalkuserConfirmOK: '連携解除',
  talkuserLoginDescription: 'メッセージサービスとGO-E-MONアカウントを連携します。ログインしてください。',
  messagingRequestTitle: 'メッセージサービスとの接続',
  messagingRequestContent: 'このタスクは、メッセージサービス {type} を通じてあなたにメッセージを送信します。',
  messagingRequestCancel: '今は設定しない',
  messagingLineRequest: '以下のリンクを開くか、QRコードをスマートフォンで読み取り、GO-E-MONアカウントを友だち登録してください。',
  messagingAddFriendOr: 'または、スマートフォンで以下のQRコードをスキャンしてください。',
  messagingAddFriendLink: '(スマートフォンの場合) GO-E-MONを友だち登録',
  messagingLineRequest1: '1. GO-E-MONを友だち登録',
  messagingLineRequest2: '2. GO-E-MONとLINEアカウントを関連付け',
  messagingLineRequest2Description1: 'LINEに送られたURLを開くか、またはLINEに送信された確認コードを以下に入力してください。',
  messagingLineRequest2Description2: 'LINEに送られたURLを開くとこのダイアログボックスは自動的に閉じます。',
  messagingLineCodeLabel: '確認コード',
  messagingLineCodePlaceholder: 'XXXXXXXXXX, ハイフンは無視されます',
  messagingMissingCode: '確認コードが見つかりません。コードを再確認し、それでも失敗するようであればコードを再発行してください。',
  messagingLineGroupRequest1: '1. GO-E-MONを友だち登録',
  messagingLineGroupRequest2: '2. GO-E-MONをグループトークに追加',
  messagingLineGroupRequest2Description: 'LINEでグループを作成し、GO-E-MONアカウントを登録してください。',
  welcomeTalkuserMessage: '連携ありがとうございます！引き続きGO-E-MONをご利用ください。\nhttps://goemon.cloud?_talklogin={type}',
  welcomeTalkuserMessageWithTask: '連携ありがとうございます！引き続きタスクの設定をお願いします。\nhttps://goemon.cloud/t/{taskId}?_talklogin={type}',
  healthRequestTitle: 'センサーサービスとの接続',
  healthRequestContent: 'このタスクは、センサーサービス {type} を通じてあなたの状態を取得します。以下のボタンからセンサーサービスとのアカウント連携を実施してください。',
  healthRequestCancel: '今は設定しない',
  healthAccessTitle: 'センサーサービスの利用開始',
  healthAccessOK: 'OK',
  healthAccessAwareDescription: 'AWARE Frameworkと連携するには、以下のQRコードまたはStudy URLをご利用ください。',
  healthAccessAwareStudyURL: 'Study URL:',
  healthAccessAwareCopied: 'Study URLをコピーしました。',
  healthLinkGarminConnect: 'Garmin Connectと連携',
  disconnectHealthuserConfirmTitle: 'センサーサービス連携の解除',
  disconnectHealthuserConfirmDescription: 'センサーサービス {type} の連携を解除します。よろしいですか？',
  disconnectHealthuserConfirmCancel: 'キャンセル',
  disconnectHealthuserConfirmOK: '連携解除',
};
